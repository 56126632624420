import styled from 'styled-components';

export const LoadingPage = styled.div`
  position: relative;
  text-align: center;
  button {
    background-color: var(--color_lvl_-5);
    border-radius: 4px;
    border: solid 1px var(--color_lvl_5);
    margin-top: 24px;
    padding: 21px 0 20px;
    text-transform: uppercase;
    width: 500px;
    &:focus,
    &:hover,
    &.nav-selected {
      background-color: var(--color_lvl_3);
      color: var(--color_lvl_-5);
      text-decoration: none;
      transform: scale(1.1);
      & span {
        color: var(--color_lvl_-5);
      }
    }
  }
`;

export const Connecting = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-items: center;
  left: 0;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  width: 100%;
  img {
    margin-top: 27px;
  }
  > div {
    margin: auto;
    z-index: 1;
  }
`;
export const Connecting_Background = styled.div`
  background-color: var(--color_lvl_-5);
  height: 100%;
  left: 0;
  opacity: 0.9;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const Background = styled.div`
  z-index: -1;
  position: absolute;
  width: 100%;
`;
export const Overlay = styled.div`
  padding-top: 625px;
  z-index: 1;
`;
export const Online = styled.div`
  height: 120px;
`;
export const Presented = styled.div`
  padding-top: 150px;
  div:first-child {
    padding-bottom: 32px;
  }
`;
export const AppSponsorAdContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  img {
    margin: 0 15px;
  }
`;
