import { Device, getDevice } from 'utils/getDevice';
import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { BillboardsConfig } from 'types/configs/billboard-config';
import { AdsConfig } from 'types/configs/ads-config';
import { AppConfig } from 'types/configs/app-config';

export const AppContext = createContext<AppContextType>({
  device: getDevice(),
  appConfigUrl: undefined,
  appConfig: undefined,
  adsConfig: undefined,
  billboardsConfig: undefined,
  webosDeviceInfo: undefined,
  currentPollingEnabled: true,
  boxscorePollingEnabled: true,
  appControlsPollingEnabled: true,
  currentFetchCounter: 0,
  boxscoreFetchCounter: 0,
  appControlsFetchCounter: 0,
  incrementCurrentFetchCounter: () => undefined,
  incrementBoxscoreFetchCounter: () => undefined,
  incrementAppControlsFetchCounter: () => undefined,
  setCurrentPollingEnabled: () => undefined,
  setBoxscorePollingEnabled: () => undefined,
  setAppControlsPollingEnabled: () => undefined,
  setAppConfigUrl: () => undefined,
  setAppConfig: () => undefined,
  setAdsConfig: () => undefined,
  setBillboardsConfig: () => undefined,
  setWebOSDeviceInfo: () => undefined,
});
AppContext.displayName = 'AppContext';

export type AppContextType = {
  device: Device;
  appConfigUrl: string | undefined;
  appConfig: AppConfig | undefined;
  adsConfig: AdsConfig | undefined;
  billboardsConfig: BillboardsConfig | undefined;
  webosDeviceInfo: DeviceInfo | undefined;
  currentPollingEnabled: boolean;
  boxscorePollingEnabled: boolean;
  appControlsPollingEnabled: boolean;
  currentFetchCounter: number;
  boxscoreFetchCounter: number;
  appControlsFetchCounter: number;
  incrementCurrentFetchCounter: () => void;
  incrementBoxscoreFetchCounter: () => void;
  incrementAppControlsFetchCounter: () => void;
  setCurrentPollingEnabled: Dispatch<SetStateAction<boolean>>;
  setBoxscorePollingEnabled: Dispatch<SetStateAction<boolean>>;
  setAppControlsPollingEnabled: Dispatch<SetStateAction<boolean>>;
  setAppConfigUrl: Dispatch<SetStateAction<string | undefined>>;
  setAppConfig: Dispatch<SetStateAction<AppConfig | undefined>>;
  setAdsConfig: Dispatch<SetStateAction<AdsConfig | undefined>>;
  setBillboardsConfig: Dispatch<SetStateAction<BillboardsConfig | undefined>>;
  setWebOSDeviceInfo: Dispatch<SetStateAction<DeviceInfo | undefined>>;
};

export const useAppContext = (): AppContextType => useContext(AppContext);
