import { logger } from '../logger';

const LOG = logger.getLogger('loadScript');

export default function loadScript(url: string): Promise<void> {
  const script = document.createElement('script');

  const result = new Promise<void>((resolve, reject) => {
    script.onload = () => {
      LOG.info(`script at ${url} loaded`);
      resolve();
    };
    script.onerror = (event, source?: string, lineno?: number, colno?: number, error?: Error) => {
      const errorMessage = `script at ${url} encountered an error ${error?.message}:\n${error?.stack}`;
      LOG.error(errorMessage);
      reject(error ?? new Error(errorMessage));
    };
  });
  script.src = url;
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);

  return result;
}
