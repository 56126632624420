export const setValue = (key: string, value: string): boolean => {
  try {
    self.localStorage.setItem(key, value);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getValue = (key: string): string | null => {
  try {
    return self.localStorage.getItem(key);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const removeItem = (key: string): void | null => {
  try {
    return self.localStorage.removeItem(key);
  } catch (error) {
    console.log(error);
    return null;
  }
};
