import { useReactiveVar } from '@apollo/client';
import { useFocusable, UseFocusableConfig, UseFocusableResult } from '@noriginmedia/norigin-spatial-navigation';
import { lastManualFocusKeyStore } from 'apolloGraphql/mml-apollo-cache';
import { FocusKeys, FOCUS_PRIORITY_KEYS } from 'components/SpacialNavigation/focusKeys';
import { useCallback, useEffect, useMemo } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';

export interface UseFocusItemConfig extends UseFocusableConfig {
  disableScrollBehaviour?: boolean;
}

/**
 * ## UseFocusItem
 * This hook is a wrapper around the useFocusable hook.
 * It contains any app wide logic that all focusable items should adhere to.
 */
export default function useFocusItem({
  focusable,
  saveLastFocusedChild,
  trackChildren,
  autoRestoreFocus,
  isFocusBoundary,
  focusKey,
  preferredChildFocusKey,
  onEnterPress,
  onEnterRelease,
  onArrowPress,
  onFocus,
  onBlur,
  extraProps,
  disableScrollBehaviour,
}: UseFocusItemConfig): UseFocusableResult {
  const manualFocusKey = useReactiveVar(lastManualFocusKeyStore);
  const useFocusableConfig: UseFocusableConfig = useMemo(
    () => ({
      focusable,
      saveLastFocusedChild,
      trackChildren,
      autoRestoreFocus,
      isFocusBoundary,
      focusKey,
      preferredChildFocusKey,
      onEnterPress,
      onEnterRelease,
      onArrowPress,
      extraProps,
      onFocus: (layout, props, details) => {
        if (!disableScrollBehaviour && !!layout.node) {
          scrollIntoView(layout.node, { behavior: 'smooth', inline: 'center', block: 'center' });
        }
        onFocus?.(layout, props, details);
      },
      onBlur: (layout, props, details) => {
        if (focusKey && FOCUS_PRIORITY_KEYS.includes(focusKey as FocusKeys)) {
          lastManualFocusKeyStore('');
        }

        // Needed to fix an issue where mouse clicks add focus to an element that keyboard navigation doesn't
        // remove on its own.
        layout.node?.blur();

        onBlur?.(layout, props, details);
      },
    }),
    [
      autoRestoreFocus,
      disableScrollBehaviour,
      extraProps,
      focusKey,
      focusable,
      isFocusBoundary,
      onArrowPress,
      onBlur,
      onEnterPress,
      onEnterRelease,
      onFocus,
      preferredChildFocusKey,
      saveLastFocusedChild,
      trackChildren,
    ],
  );
  const {
    ref,
    focused,
    hasFocusedChild,
    focusKey: resultFocusKey,
    setFocus,
    navigateByDirection,
    pause,
    resume,
    updateAllLayouts,
    getCurrentFocusKey,
  } = useFocusable(useFocusableConfig);

  // Prevents focus from being stolen from specified components
  const updateFocus = useCallback(
    (callback: UseFocusableResult['setFocus'], focusKey: string) => {
      const triggeringComponentFocusKey = resultFocusKey as FocusKeys;
      const newFocusPriorityIndex = FOCUS_PRIORITY_KEYS.indexOf(focusKey as FocusKeys);
      if (
        !FOCUS_PRIORITY_KEYS.includes(manualFocusKey as FocusKeys) ||
        triggeringComponentFocusKey === manualFocusKey ||
        (newFocusPriorityIndex !== -1 &&
          newFocusPriorityIndex < FOCUS_PRIORITY_KEYS.indexOf(manualFocusKey as FocusKeys))
      ) {
        callback(focusKey);
        lastManualFocusKeyStore(focusKey);
      }
    },
    [resultFocusKey, manualFocusKey],
  );
  useEffect(() => {
    return () => {
      if (manualFocusKey === focusKey && FOCUS_PRIORITY_KEYS.includes(manualFocusKey as FocusKeys)) {
        lastManualFocusKeyStore('');
      }
    };
  }, [manualFocusKey, focusKey]);
  return {
    ref,
    focused,
    hasFocusedChild,
    focusKey: resultFocusKey,
    navigateByDirection,
    pause,
    resume,
    updateAllLayouts,
    getCurrentFocusKey,
    setFocus: useCallback((focusKey) => updateFocus(setFocus, focusKey), [setFocus, updateFocus]),
    focusSelf: useCallback(() => updateFocus(setFocus, resultFocusKey), [setFocus, resultFocusKey, updateFocus]),
  };
}
