let cachedMarketingCloudVisitorID = '';
let cachedAnonymousId = '';
let isMCVIDDirty = true;
let isAIDDirty = true;

export function getMarketingCloudVisitorID(): string {
  if (isMCVIDDirty) {
    isMCVIDDirty = false;
    cachedMarketingCloudVisitorID = self.s?.visitor?.getMarketingCloudVisitorID() ?? '';
  }

  return cachedMarketingCloudVisitorID;
}

export function getAnonymousId() {
  if (isAIDDirty) {
    const anonId = analytics?.user?.()?.anonymousId?.();
    if (anonId != null) {
      isAIDDirty = false;
      cachedAnonymousId = anonId;
    }
  }

  return cachedAnonymousId;
}

export function invalidateMarketingCloudVisitorID() {
  isMCVIDDirty = true;
}
