/**
 * Does a shallow comparison to check if each element in the first array
 * is equal to the corresponding element in the second array
 */
export function arraysAreEqual<T>(arrayA: T[], arrayB: T[]): boolean {
  if (arrayA.length !== arrayB.length) return false;

  return arrayA.every((element, index) => element === arrayB[index]);
}

/**
 * ## Dedup: to de-duplicate
 * Returns a new array with all duplicate entries removed.
 * Does not altar the original array.
 */
export function arrayDedup<T>(array: T[]) {
  return [...new Set(array)];
}

/**
 * Returns a new array of values present in both `arrayA` and `arrayB`
 */
export function arrayIntersection<T>(arrayA: T[], arrayB: T[]) {
  return arrayA.filter((value) => arrayB.includes(value));
}

/**
 * Returns a new array of values present in `arrayA` but not present in `arrayB`
 */
export function arrayDifference<T>(arrayA: T[], arrayB: T[]) {
  return arrayA.filter((value) => !arrayB.includes(value));
}

/**
 * Returns a new array of values that are present in either array but not present in both
 */
export function arraySymmetricDifference<T>(arrayA: T[], arrayB: T[]) {
  return arrayDifference(arrayA, arrayB).concat(arrayDifference(arrayB, arrayA));
}
