import {
  basePropertyStore,
  freeviewStateStore,
  gameCenterStateStore,
  pageInfoState,
  persistentPropertyStore,
  userPropertyStore,
} from 'apolloGraphql/mml-apollo-cache';
import { useCallback, useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useAuthWrapper } from '../components/VideoPlayer/tve/useAuthWrapper';
import { FreeViewStateNames } from 'components/VideoPlayer/tve/freeview-states';
import { UserStatusAuth } from 'state-machines/analytic-states';
import { AuthStateNames } from 'components/VideoPlayer/tve/auth-states';
import { getAnonymousId, getMarketingCloudVisitorID } from 'utils/heartbeatWrapper';
import { getDevice } from 'utils/getDevice';
import { AppWorkerFactoryInstance } from 'web-workers/AppWorkerFactory';

const device = getDevice();
const AppWorkerProxy = AppWorkerFactoryInstance.create(device);

const screenViews = [
  {
    pageName: 'Watch',
    section: 'watch',
    subsection: 'watch',
    url: 'ncaa:watchmml:watch',
    path: '/',
    title: 'Watch',
  },
  {
    pageName: 'Settings',
    section: 'settings',
    subsection: 'settings',
    url: 'ncaa:watchmml:settings',
    path: '/settings',
    title: 'Settings',
  },
  {
    pageName: 'Official Bracket',
    section: 'scores',
    subsection: 'scores:bracket',
    url: 'ncaa:watchmml:scores:bracket',
    path: '/bracket',
    title: 'Official Bracket',
  },
  {
    pageName: 'Schedule',
    section: 'scores',
    subsection: 'scores:schedule',
    url: 'ncaa:watchmml:scores:schedule',
    path: '/scores',
    title: 'Schedule',
  },
  {
    pageName: 'bcg registration landing',
    section: 'bcg',
    subsection: 'bcg:registration',
    url: 'ncaa:watchmml:bcg:registration',
    path: '/bracketchallenge',
    title: 'bcg registration landing',
  },
  {
    pageName: 'bcg dashboard',
    section: 'bcg',
    subsection: 'bcg:dashboard',
    url: 'ncaa:watchmml:bcg:dashboard',
    path: '/bcgdashboard',
    title: 'bcg dashboard',
  },
  {
    pageName: 'bcg qr code',
    section: 'bcg',
    subsection: 'bcg:dashboard',
    url: 'ncaa:watchmml:bcg:dashboard:bcg_qr_code',
    path: '/bcgqrcode',
    title: 'bcg qr code',
  },
  {
    pageName: 'bcg bracket detail',
    section: 'bcg',
    subsection: 'bcg:bracket',
    url: 'ncaa:watchmml:bcg:bracket:bcg_bracket_detail',
    path: '/bcgbracket',
    title: 'bcg bracket detail',
  },
  {
    pageName: 'Support',
    section: 'settings',
    subsection: 'settings:support',
    url: 'ncaa:watchmml:settings:support',
    path: '/privacy',
    title: 'Supportl',
  },
  {
    pageName: 'do not sell',
    section: 'settings',
    subsection: 'settings:donotsell',
    url: 'ncaa:watchmml:settings:donotsell',
    path: '/donotsell',
    title: 'do not sell',
  },
  {
    pageName: 'ways to watch',
    section: 'settings',
    subsection: 'settings:ways_to_watch',
    url: 'ncaa:watchmml:settings:ways_to_watch',
    path: '/waystowatch',
    title: 'ways to watch',
  },
  {
    pageName: 'game center',
    section: 'gamecenter',
    subsection: 'gamecenter:game',
    url: 'ncaa:watchmml:gamecenter:game:<gameID>',
    path: '/game',
    title: 'game center',
    gameID: '',
    channel: '',
    gameState: '',
  },
  {
    section: 'unknown',
    subsection: 'unknown',
    url: 'unknown',
    path: '/default',
    title: '',
  },
];

export function useAnalytics(): void {
  const pageInfo = useReactiveVar(pageInfoState);
  const persistentPropertyState = persistentPropertyStore();
  const userPropertyState = useReactiveVar(userPropertyStore);
  const authProperties = useAuthWrapper().authStateMachine;
  const freeViewState = useReactiveVar(freeviewStateStore);
  const mcid = useRef('');

  const screenView = useCallback(
    (path: string, referrer: string, adobeid: string | undefined) => {
      let info = screenViews
        .filter((item) => {
          if (path.indexOf('/bcgbracket') > -1) {
            return item.path === '/bcgbracket';
          } else if (path.indexOf('/game/') > -1) {
            return item.path === '/game';
          }
          return item.path === path;
        })
        .map((item) => {
          if (item.pageName === 'game center') {
            const gameInfo = gameCenterStateStore();
            item.url = `ncaa:watchmml:gamecenter:game:${gameInfo.bracketId.toString()}`;
            item.gameID = gameInfo.bracketId.toString();
            item.gameState = gameInfo.gameState;
            item.channel = gameInfo.broadcaster ?? '';
          }
          return item;
        });

      info = !info.length ? screenViews.filter((item) => item.path === '/default') : info;

      const baseProperties = basePropertyStore({
        ...basePropertyStore(),
        ...info[0],
        timestamp: Date.now().toString(),
        path: info[0].pageName ?? path,
        referrer,
      });

      AppWorkerProxy.analyticsPage({
        anonymousId: getAnonymousId(),
        userId: userPropertyState.bcgId ?? undefined,
        name: baseProperties.pageName,
        properties: baseProperties,
        integrations: {
          'Adobe Analytics': {
            marketingCloudVisitorId: adobeid ?? null,
          },
        },
        context: {
          traits: {
            ...persistentPropertyState,
            ...userPropertyState,
          },
        },
      });
    },
    [persistentPropertyState, userPropertyState],
  );

  useEffect(() => {
    if (mcid.current === '' && window?.s?.visitor) {
      mcid.current = getMarketingCloudVisitorID();
    }

    if (pageInfo.path !== '') {
      screenView(pageInfo.path, pageInfo.referrer, mcid.current);
    }
  }, [pageInfo, screenView]);

  useEffect(() => {
    if (userPropertyState.authStatus !== '') {
      AppWorkerProxy.analyticsIdentify({
        traits: userPropertyState,
        ...persistentPropertyState,
        ...userPropertyState,
        anonymousId: getAnonymousId(),
        userId: userPropertyState.bcgId ?? undefined,
      });
    }
  }, [persistentPropertyState, userPropertyState]);

  useEffect(() => {
    let userPropertiesObject = {};
    if (authProperties != null && authProperties.userProperties) {
      const { adobeHashID, mvpd, mvpdDisplayName } = authProperties.userProperties;
      if (adobeHashID !== userPropertyStore().adobeHashID || mvpd !== userPropertyStore().mvpd) {
        userPropertiesObject = {
          adobeHashID: adobeHashID,
          mvpd: mvpd,
          mvpdDisplayName: mvpdDisplayName,
          adobeId: getMarketingCloudVisitorID(),
        };
      }
    }

    const authState = authProperties.state == AuthStateNames.AUTHENTICATED ? 'LOGGED_IN' : 'NOT_LOGGED_IN';
    const state = (authState +
      (freeViewState === FreeViewStateNames.USING_FREEVIEW ? '_REMAINING' : '_EXPIRED')) as keyof typeof UserStatusAuth;

    if (userPropertyStore().authStatus !== UserStatusAuth[state]) {
      userPropertyStore({
        ...userPropertyStore(),
        ...userPropertiesObject,
        authStatus: UserStatusAuth[state],
      });
    } else if (Object.keys(userPropertiesObject).length > 0) {
      userPropertyStore({
        ...userPropertyStore(),
        ...userPropertiesObject,
      });
    }
  }, [authProperties, freeViewState]);
}
