import log from 'loglevel';
import { SessionName } from 'state-machines/video-session-state';
import { UserConsent } from './user-consent';

// ------------------------------------------------------------------------
// App wide interfaces and constants live hear
// ------------------------------------------------------------------------

export enum PrimitiveSide {
  Right = 'RIGHT',
  Left = 'LEFT',
}

export enum PlayerSessionType {
  Primary = 'primary',
  Secondary = 'secondary',
}

/**
 * Direction is an enum of possible gamepad events which can fire.
 */
export enum Button {
  Submit = 0,
  Back = 1,
  X = 2,
  Y = 3,
  TabLeft = 4, // Left Bumper
  TabRight = 5, // Right Bumper
  TabUp = 6, // Left Trigger
  TabDown = 7, // Right Trigger
  View = 8, // Left small button, aka start
  Menu = 9, // Right small button
  Up = 12,
  Down = 13,
  Left = 14,
  Right = 15,

  Rewind = 89,
  FastForward = 90,
  PlayPause = 85,

  FireMediaPlayPause = 179,
  FireMediaFastForward = 228,
  FireMediaRewind = 227,
}

/**
 * The sef of left/right/up/down directional buttons
 */
export const directionalButtons: ReadonlySet<Button> = new Set([Button.Left, Button.Right, Button.Up, Button.Down]);

/**
 * The set of gamepad buttons that aren't left/right/up/down focuses
 */
export const nonDirectionalButtons = new Set([
  Button.Submit,
  Button.Back,
  Button.X,
  Button.Y,
  Button.TabLeft,
  Button.TabRight,
  Button.TabUp,
  Button.TabDown,
  Button.View,
  Button.Menu,
  Button.FastForward,
  Button.Rewind,
  Button.PlayPause,
]);

/**
 * The set of all button codes.
 */
export const buttons: ReadonlySet<Button> = new Set([...nonDirectionalButtons, ...directionalButtons]);

export interface IInputObservation {
  button: Button;
  event?: Event;
}

// ------------------------------------------------------------------------
// video interfaces & constants ...
// ------------------------------------------------------------------------
export enum VideoLinkType {
  Vod = 'vod',
  Preview = 'preview',
  Condensed = 'condensed',
  Recap = 'recap',
  Live = 'live',
}

export enum PlayerAction {
  PLAY = 'PLAY',
  STOP = 'STOP',
  PAUSE = 'PAUSE',
}
export enum ConvivaMeta {
  COMPANY_ID = '369463fd-e0a2-4ce9-9ad4-e698aabe5a77',
  APP_ID = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuZXR3b3JrIjoid20iLCJwcm9kdWN0IjoidXBmcm9udHNwbGF5ZXIiLCJwbGF0Zm9ybSI6IndlYiIsImFwcElkIjoid20tdXBmcm9udHNwbGF5ZXItd2ViLW14MmhsaSJ9.jPjvTAmYdDOTavc3-JUdF448HIjBeDWgEh8pmxtWQ7w',
}

declare global {
  interface Window {
    Android?: Android;
    AndroidVideo?: AndroidVideo;
    chrome?: Chrome;
    OneTrust: OneTrust;
    OneTrustActiveGroups: string;
    PWA_LOGGER: log.RootLogger;
    s: {
      visitor: {
        getMarketingCloudVisitorID(): string | undefined;
      };
    };
    UWP: {
      adID?: string;
      deviceID?: string;
      deviceType?: string;
      error?: string;
      fullOsVersion?: string;
      manufacturer?: string;
      marketingName?: string;
      model?: string;
      modelVersion?: string;
      name?: string;
      osVersion?: string;
    };
    UWPBridge: {
      deepLinkToStorePage?: (message: XboxMessage) => void;
    };
    webOS?: WebOS;
    WBD?: {
      UserConsent: UserConsent;
    };
  }

  interface Document {
    msHidden?: string;
    webkitHidden?: string;
  }

  interface Navigator {
    gamepadInputEmulation?: 'mouse' | 'keyboard' | 'gamepad';
  }
}

type Chrome = {
  webview?: Webview;
};

type Webview = {
  postMessage?(json: string): void;
};

type XboxMessage = {
  methodName: 'deepLink';
  storeUri: string;
};

export type VideoStartListenerEvent =
  | string
  | { eventName: 'live_video_start' | 'vod_video_start'; sessionName?: SessionName };

self.UWP = self.UWP || {};
self.UWPBridge = {};
self.UWPBridge.deepLinkToStorePage = (message: XboxMessage) => {
  self.chrome?.webview?.postMessage?.(JSON.stringify(message));
};
self.s = self.s || {};
