import Axios from 'axios';
import { logger } from 'logger';
import { useCallback, useEffect, useState } from 'react';
import type { TaglessRequestResponse } from 'types/configs/ads-config';
import { getDevice } from 'utils/getDevice';
import Monitor from 'utils/monitor';
import { getUSPInt } from 'utils/privacy';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = logger.getLogger('useSponsorAd');

export enum Status {
  Idle = 'IDLE',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Error = 'ERROR',
}
export interface UseSponsorAdProps {
  url: string | undefined;
  montageId?: number;
  shouldFireTrackersImmediately?: boolean;
  shouldLoadTaglessRequestImmediately?: boolean;
}

interface UseSponsorAdReturnType {
  fireTrackers: () => void;
  loadPixel: () => Promise<TaglessRequestResponse | undefined>;
  sponsorAd?: TaglessRequestResponse | null;
  status: Status;
}

const device = getDevice();

export default function useSponsorAd(props: UseSponsorAdProps): UseSponsorAdReturnType {
  const { shouldFireTrackersImmediately = true, shouldLoadTaglessRequestImmediately = true } = props;

  const [sponsorAd, setSponsorAd] = useState<TaglessRequestResponse | null>();
  const [status, setStatus] = useState<Status>(Status.Idle);

  const loadPixel = useCallback(async () => {
    if (!props.url) return;

    try {
      // Get App Config
      setStatus(Status.InProgress);
      const response = await Axios.get<TaglessRequestResponse>(
        doUrlSubstitution(props.url, { montageId: props.montageId }),
      );
      setSponsorAd(response.data);
      setStatus(Status.Completed);

      return Promise.resolve(response.data);
    } catch (error) {
      // console.error('ERROR:', error);
      setStatus(Status.Error);
      return Promise.reject();
    }
  }, [props.montageId, props.url]);

  const fireTrackers = useCallback(async () => {
    try {
      const pixels = [sponsorAd?.defaultImpression].concat(sponsorAd?.thirdPartyTrackers).filter(Boolean) as string[];
      await Promise.all(pixels.map((pixel) => Axios.get(pixel)));
    } catch (error) {
      Monitor.sendError(error as string | Error);
    }
  }, [sponsorAd?.defaultImpression, sponsorAd?.thirdPartyTrackers]);

  useEffect(() => {
    if (props.url && shouldLoadTaglessRequestImmediately) loadPixel();
  }, [loadPixel, props.montageId, props.url, shouldLoadTaglessRequestImmediately]);

  useEffect(() => {
    if (shouldFireTrackersImmediately && status === Status.Completed) fireTrackers();
  }, [fireTrackers, shouldFireTrackersImmediately, status]);

  return { fireTrackers, loadPixel, sponsorAd, status };
}

function getRandomIntInclusive(min: number, max: number) {
  // choose an integer between `min` and `max` and allow 9 and 16 as possible integers
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1) + min);
}

function getRandom() {
  return (Math.random() + '').slice(2, 2 + getRandomIntInclusive(9, 16));
}

type DoUrlSubstitutionOptions = {
  montageId?: number;
  fastbreakPlacement?: string;
};

function doUrlSubstitution(url: string, options?: DoUrlSubstitutionOptions) {
  url = url
    .replace('[RDP]', getUSPInt() + '')
    .replace('[RANDOM]', getRandom())
    .replace('[DEVICE]', device)
    .replace('[MODE]', 'bgd');

  if (options?.montageId) {
    url = url.replace('[PLACEMENT]', options.montageId + '');
  } else if (options?.fastbreakPlacement) {
    /** @todo figure out what this value is supposed to be and where it comes from */
    url = url.replace('[PLACEMENT]', options.fastbreakPlacement);
  }

  return url;
}
