export function getUrlParam(name: string): string {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]').toLowerCase();
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(self.location.search.toLowerCase());
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function debugEnabled(debugs: string[] = ['debug', 'debugConfig', 'topdebug', 'fw']): boolean {
  for (let i = 0; i < debugs.length; i++) {
    const href = decodeURIComponent(location.href);
    const regEx = new RegExp('[?&]' + debugs[i] + '=([^&#]*)', 'i');
    const value = regEx.exec(href);
    if (value != null) {
      return true;
    }
  }

  return false;
}

export function hasWindow(): boolean {
  return typeof window === 'object';
}

export function isWebWorker() {
  return typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope;
}
