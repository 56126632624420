/**
 * Return the public path (a way to test environment).
 */
export function getPublicPath(): string {
  return process.env.PUBLIC_PATH || '';
}

/**
 * Return if this production.
 */
export const isProdEnv = (): boolean => {
  if ((process?.env?.NODE_ENV?.toLowerCase() ?? '') === 'production') {
    return true;
  }
  if ((process?.env?.APP_ENV?.toLowerCase() ?? '') === 'production') {
    return true;
  }
  return false;
};

/**
 * @return true if the splash screen should be skipped
 */
export const skipSplashScreen = (): boolean => {
  return (process?.env?.DISABLE_SPLASH?.toLowerCase() ?? '') === 'true';
};

/**
 * @return true if a mock service worker with stubbed response
 * values should be used instead of making real network calls
 */
export const shouldUseMockServiceWorker = (): boolean => {
  return (process?.env?.USE_MOCK_SERVICE_WORKER?.toLowerCase() ?? '') === 'true';
};

let cachedIsProdDomain: boolean | undefined = undefined;
export const isProdDomain = (): boolean => {
  if (cachedIsProdDomain === undefined) {
    // Treat staging as effectively the same as prod
    cachedIsProdDomain = getDomainEnv() === DomainEnv.PROD || getDomainEnv() === DomainEnv.STAGING;
  }
  return cachedIsProdDomain;
};

let cachedIsDemoDomain: boolean | undefined = undefined;
export const isDemoDomain = (): boolean => {
  if (cachedIsDemoDomain === undefined) {
    cachedIsDemoDomain = getDomainEnv() === DomainEnv.DEMO;
  }
  return cachedIsDemoDomain;
};

export enum DomainEnv {
  PROD = 'prod',
  STAGING = 'staging',
  QA = 'qa',
  DEV = 'dev',
  DEMO = 'demo',
  LOCAL = 'local',
}
let cachedDomainEnv: DomainEnv;
/**
 * Returns the cached domain environment
 */
export const getDomainEnv = (): DomainEnv => {
  if (cachedDomainEnv === undefined) {
    if (location.origin.includes('pwa.ncaa.com')) cachedDomainEnv = DomainEnv.PROD;
    if (location.origin.includes('staging-pwa.nonprod.ncaa.com')) cachedDomainEnv = DomainEnv.STAGING;
    if (location.origin.includes('demo-pwa.nonprod.ncaa.com')) cachedDomainEnv = DomainEnv.DEMO;
    if (location.origin.includes('dev-pwa.nonprod.ncaa.com')) cachedDomainEnv = DomainEnv.DEV;
    if (location.origin.includes('qa-pwa.nonprod.ncaa.com')) cachedDomainEnv = DomainEnv.QA;
    if (location.origin.includes('local.ncaa.com')) cachedDomainEnv = DomainEnv.LOCAL;
  }

  return cachedDomainEnv;
};

/**
 * Return the absolute path to the static asset.
 * This will prepend the given path with the `PUBLIC_PATH` env var.
 *
 * @param path The path to the static asset. This should start with a slash (`/`).
 */
export function asset(path: string): string {
  const publicUrl = getPublicPath().replace(/\/$/, '');
  return `${publicUrl}/${path.replace(/^\/+/, '')}`;
}
