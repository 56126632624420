import { emitCustomEvent } from 'react-custom-events';
import { logger } from 'logger';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = logger.getLogger('AppMessage');

export const SHOW_BILLBOARD_MESSAGE = 'SHOW_BILLBOARD_MESSAGE';
export const HIDE_BILLBOARD_MESSAGE = 'HIDE_BILLBOARD_MESSAGE';

export type SimpleMessageCallback = (primaryButtonTapped?: boolean) => void;

class MessageCommander {
  private static _instance: MessageCommander;
  private constructor() {
    // nothing here ...
  }
  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public show(
    key: BillboardKeys,
    networkDisplayName?: string,
    callback?: SimpleMessageCallback,
    options?: MessageEventDataOptions,
  ): void {
    const data = { billboardKey: key, networkDisplayName, callback, options } as MessageEventData;
    emitCustomEvent(SHOW_BILLBOARD_MESSAGE, data);
  }
  public hide(): void {
    emitCustomEvent(HIDE_BILLBOARD_MESSAGE);
  }
}

export const AppMessage = MessageCommander.Instance;

export interface MessageEventDataOptions {
  btnLabel1?: string;
  btnBackgroundColor?: string;
  secondaryButtonCallback?: boolean;
}

export interface MessageEventData {
  billboardKey: BillboardKeys;
  networkDisplayName?: string;
  callback?: SimpleMessageCallback;
  options?: MessageEventDataOptions;
}

export enum BillboardKeys {
  FREEVIEW_EXPIRED = 'M1',
  TVE_AUTHZ_ERROR = 'M2',
  TVE_GEOBLOCKED = 'M3',
  FREEVIEW_NOT_AUTHORIZED = 'M4',
  TVE_GENERIC_AUTH_ERROR = 'M5',
  TVE_AUTH_REQUIRED = 'M6',
  VIDEO_ERROR = 'M7',
  LOW_BANDWIDTH = 'M8',
  VIDEO_ERROR_RETRY = 'M11',
  GENERIC_ERROR = 'M10',
  FREEVIEW_NETWORK_ERROR = 'M26',
  VIDEO_FINAL = 'S1',
  ENABLE_GEO = 'S2',
  MULTIGAME_TOAST = 'S3',
  CBS_NOT_AVAILABLE_FIRE = 'F1',
  CBS_NOT_AVAILABLE_XBOX = 'W1',
  CBS_NOT_AVAILABLE_ANDROID_TV = 'G1',
  CBS_NOT_AVAILABLE_LGTV = 'L1',
  BCG_SIGNOUT_CONFIRMATION = 'P1',
}

export const BillboardTitles = {
  M1: 'FREE PREVIEW ENDED',
  M2: 'AN ERROR OCCURRED',
  M3: 'AN ERROR OCCURRED',
  M4: 'CHANNEL NOT IN PACKAGE',
  M5: 'AN ERROR OCCURRED',
  M6: 'TVE AUTHENTICATION REQUIRED',
  M7: 'VIDEO PLAYBACK ERROR',
  M8: 'LOW BANDWIDTH',
  M10: 'AN ERROR OCCURRED',
  M11: 'VIDEO PLAYBACK ERROR_RETRY',
  M26: 'FREEVIEW NETWORK ERROR',
  S1: 'STREAM HAS ENDED',
  S2: '',
  S3: '',
  F1: 'CBS GAMES NOT AVAILABLE',
  W1: 'CBS GAMES NOT AVAILABLE',
  G1: 'CBS GAMES NOT AVAILABLE',
  L1: 'CBS GAMES NOT AVAILABLE',
  P1: 'ARE YOU SURE?',
};

export const BillboardButtonAction = {
  M1: 'SIGN IN',
  M2: '',
  M3: '',
  M4: '',
  M5: '',
  M6: '',
  M7: '',
  M8: '',
  M10: '',
  M11: 'RETRY',
  M26: '',
  S1: '',
  S2: '',
  S3: '',
  F1: '',
  W1: '',
  G1: '',
  L1: '',
  P1: 'SIGN OUT',
};
