import React, { FC, PropsWithChildren, ReactElement, useCallback, useState } from 'react';
import SponsorAd, { SponsorAdProps } from './SponsorAd';

interface SponsorAdGroupProps extends Omit<SponsorAdProps, 'url' | 'onAdLoaded'> {
  ads: string[];
  onEmpty?: () => ReactElement | null;
  revealWithAdLoaded?: {
    inside?: () => ReactElement | null;
    outside?: () => ReactElement | null;
  };
  /** This is a component where the children prop will be the rendered sponsor image */
  Wrapper: FC<PropsWithChildren>;
}

const SponsorAdGroupMemoized: FC<SponsorAdGroupProps> = (props) => {
  const [hasAds, setHasAds] = useState(false);
  const { ads, description, onEmpty, revealWithAdLoaded, Wrapper, ...rest } = props;

  const onAdLoaded = useCallback(() => {
    setHasAds(true);
  }, [setHasAds]);

  const Ads = (
    <>
      {ads.map((url, index) => (
        <SponsorAd
          key={url + index}
          url={url}
          description={description + '_' + index}
          onAdLoaded={onAdLoaded}
          {...rest}
        />
      ))}
    </>
  );

  // avoid conditionals for "Ads" to avoid subsequent call when hasAds becomes true
  return (
    <>
      {hasAds && revealWithAdLoaded?.outside?.()}
      <Wrapper>
        {hasAds && revealWithAdLoaded?.inside?.()}
        {Ads}
      </Wrapper>
    </>
  );
};

export const SponsorAdGroup = React.memo(SponsorAdGroupMemoized);
