export type VodState = {
  contentIndex: number;
  tilePlaying: number;
  isPlaylist: boolean;
  spotlightIndex?: number;
  video: {
    hasMediaId: boolean;
    hasVideoUrl: boolean;
    mediaId?: string;
    videoUrl?: string;
    title: string;
    thumbnail: string;
    typeName: 'FeaturedSpotlight' | 'TrendingContent' | 'VodList' | 'WscContent' | 'Stream';
    url: string;
  };
};

export const VOD_INITIAL_STATE: VodState = {
  contentIndex: 0,
  tilePlaying: 0,
  isPlaylist: true,
  video: {
    hasMediaId: true,
    hasVideoUrl: false,
    mediaId: 'testmediaid',
    title: 'Test Video Stream',
    typeName: 'VodList',
    thumbnail: 'https://i.dont.know/thumbnailUrl.png',
    url: '',
  },
};
