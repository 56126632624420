/**
 * Array for storing all of the button input key codes
 */

/*
 *
	GAMEPAD (REGULAR XBOX CONTROLLER) INPUTS
	  210 = // Right Joystick Click
		211 = // GamepadLeftThumbstickUp
		203 = // GamepadDPadUp
		212 = // GamepadLeftThumbstickDown
		204 = // GamepadDPadDown
		214 = // GamepadLeftThumbstickLeft
		205 = // GamepadDPadLeft
		213 = // GamepadLeftThumbstickRight
		206 = // GamepadDPadRight
		195 = // A Button
		196 = // B button
		197 = // X Button
		198 = // Y Button
		208 = // View button
		207 = // Menu button
		200 = // Left Bumper
		199 = // Right Bumper
		201 = // Left Trigger
		202 = // Right Trigger
 *
 */

/*
 *
	MEDIA REMOTE CONTROLLER INPUTS
		// note that these are all covered by the key values above
		205 = // Left
		206 = // Right
		203 = // Up
		204 = // Down
		195 = // A / Select Button
		196 = // B button
		207 = // Menu button
		208 = // View button
 *
 */

/*
 *
	KEYBOARD INPUTS
		66 = // b key
		65 = // a key
		39 = // right arrow
		37 = // left arrow
		38 = // up arrow
		40 = // down arrow
		70 = // f key
		82 = // r key
		77 = // m key
 *
 */

export enum Keysxbox {
  A_GAMEPAD = 195, // A Button
  A_KEYBOARD = 65, // A key (keyboard)
  B_GAMEPAD = 27, // B button
  B_KEYBOARD = 66, // B key (keyboard)
  BACK = B_GAMEPAD, //Back button
  CLICK_GAMEPAD_RIGHTJOYSTICK = 210, //Right Joystick Click
  DOWN_GAMEPAD_DPAD = 204, // GamepadDPadDown
  DOWN_GAMEPAD_JOYSTICK = 40, // GamepadLeftThumbstickDown
  DOWN_KEYBOARD = DOWN_GAMEPAD_JOYSTICK, // down arrow (keyboard)
  F_KEYBAORD = 70, // F key (keyboard)
  LEFT_BUMPER_GAMEPAD = 200, // Left Bumper
  LEFT_GAMEPAD_DPAD = 205, // GamepadDPadLeft
  LEFT_GAMEPAD_JOYSTICK = 37, // GamepadLeftThumbstickLeft
  LEFT_KEYBOARD = LEFT_GAMEPAD_JOYSTICK, // left arrow (keyboard)
  LEFT_TRIGGER_GAMEPAD = 201, // Left Trigger
  M_KEYBOARD = 77, // M key (keyboard)
  MENU_GAMEPAD = 207, // Menu button
  R_KEYBOARD = 82, // R key (keyboard)
  RIGHT_BUMPER_GAMEPAD = 199, // Right Bumper
  RIGHT_GAMEPAD_DPAD = 206, // GamepadDPadRight
  RIGHT_GAMEPAD_JOYSTICK = 39, // GamepadLeftThumbstickRight
  RIGHT_KEYBOARD = RIGHT_GAMEPAD_JOYSTICK, // right arrow (keyboard)
  RIGHT_TRIGGER_GAMEPAD = 202, // Right Trigger
  UP_GAMEPAD_DPAD = 203, // GamepadDPadUp
  UP_GAMEPAD_JOYSTICK = 38, // GamepadLeftThumbstickUp
  UP_KEYBOARD = UP_GAMEPAD_JOYSTICK, // up arrow (keyboard)
  X_GAMEPAD = 197, // X Button
  Y_GAMEPAD = 198, // Y Button
}

export enum Keysfiretv {
  DOWN = 40,
  LEFT = 37,
  RIGHT = 39,
  ENTER = 13,
  PLAY_PAUSE = 179,
  FAST_FWD = 228,
  REWIND = 227,
  BACK = 27,
  INFO = 18,
}

export enum KeysKeyboard {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Shift = 16,
  Ctrl = 17,
  Alt = 18,
  MacOption = Alt,
  PauseBreak = 19,
  CapsLock = 20,
  Escape = 27,
  Space = 32,
  PageUp = 33,
  PageDown = 34,
  End = 35,
  Home = 36,

  LeftArrow = 37,
  UpArrow = 38,
  RightArrow = 39,
  DownArrow = 40,

  Insert = 45,
  Delete = 46,

  Zero = 48,
  ClosedParen = Zero,
  One = 49,
  ExclamationMark = One,
  Two = 50,
  AtSign = Two,
  Three = 51,
  PoundSign = Three,
  Hash = PoundSign,
  Four = 52,
  DollarSign = Four,
  Five = 53,
  PercentSign = Five,
  Six = 54,
  Caret = Six,
  Hat = Caret,
  Seven = 55,
  Ampersand = Seven,
  Eight = 56,
  Star = Eight,
  Asterik = Star,
  Nine = 57,
  OpenParen = Nine,

  A = 65,
  B = 66,
  C = 67,
  D = 68,
  E = 69,
  F = 70,
  G = 71,
  H = 72,
  I = 73,
  J = 74,
  K = 75,
  L = 76,
  M = 77,
  N = 78,
  O = 79,
  P = 80,
  Q = 81,
  R = 82,
  S = 83,
  T = 84,
  U = 85,
  V = 86,
  W = 87,
  X = 88,
  Y = 89,
  Z = 90,

  LeftWindowKey = 91,
  MacCommandKey = LeftWindowKey,
  RightWindowKey = 92,
  SelectKey = 93,

  Numpad0 = 96,
  Numpad1 = 97,
  Numpad2 = 98,
  Numpad3 = 99,
  Numpad4 = 100,
  Numpad5 = 101,
  Numpad6 = 102,
  Numpad7 = 103,
  Numpad8 = 104,
  Numpad9 = 105,

  Multiply = 106,
  Add = 107,
  Subtract = 109,
  DecimalPoint = 110,
  Divide = 111,

  F1 = 112,
  F2 = 113,
  F3 = 114,
  F4 = 115,
  F5 = 116,
  F6 = 117,
  F7 = 118,
  F8 = 119,
  F9 = 120,
  F10 = 121,
  F11 = 122,
  F12 = 123,

  NumLock = 144,
  ScrollLock = 145,

  SemiColon = 186,
  Equals = 187,
  Comma = 188,
  Dash = 189,
  Period = 190,
  UnderScore = Dash,
  PlusSign = Equals,
  ForwardSlash = 191,
  Tilde = 192,
  GraveAccent = Tilde,

  OpenBracket = 219,
  ClosedBracket = 221,
  Quote = 222,
}

export enum Keysandroidtv {
  BACK = 4,
}

export enum Keyslgtv {
  LEFT = 37,
  UP = 38,
  RIGHT = 39,
  DOWN = 40,
  OK = 13,
  BACK = 461,
  RED = 403,
  GREEN = 404,
  YELLOW = 405,
  BLUE = 406,
  REWIND = 412,
  PLAY = 415,
  PAUSE = 19,
  FAST_FORWARD = 417,
}

/*
  "23": "ENTER",
            "21": "LEFT",
            "19": "UP",
            "22": "RIGHT",
            "20": "DOWN",
            "48": "0",
            "49": "1",
            "50": "2",
            "51": "3",
            "52": "4",
            "53": "5",
            "54": "6",
            "55": "7",
            "56": "8",
            "57": "9",
            "4": "BACK",
            "103": "NEXT",
            "102": "PREV",
            "90": "FAST_FWD",
            "89": "REWIND",
            "108": "PLAY_PAUSE",
            "82": "INFO",
            "100": "SUBTITLES",
            "127": "PAUSE",
            "86": "STOP",
            "99": "BACKSPACE",
            "84": "SEARCH"\

            */
