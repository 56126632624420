import { logger } from 'logger';
import { Route, Routes, RouteProps, Navigate } from 'react-router-dom';
import { lazy, LoadableComponent } from '@loadable/component';
import { VideoLinkType } from 'types/types';

// lazy loaded page routes
const HomePage = lazy(
  () =>
    import(
      /* webpackChunkName: "HomePage" */
      /* webpackPrefetch: true */
      'pages/Home/HomePage'
    ),
);
const BracketPage = lazy(
  () =>
    import(
      /* webpackChunkName: "BracketPage" */
      /* webpackPrefetch: true */
      'pages/Bracket/BracketPage'
    ),
);
const BCGBracketPage = lazy(
  () =>
    import(
      /* webpackChunkName: "BCGBracketPage" */
      /* webpackPrefetch: true */
      'pages/BCGBracket/BCGBracketPage'
    ),
);
const ScoreboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ScoreboardPage" */
      /* webpackPrefetch: true */
      'pages/Scoreboard/ScoreboardPage'
    ),
);
const BracketChallengePage = lazy(
  () =>
    import(
      /* webpackChunkName: "BracketChallengePage" */
      /* webpackPrefetch: true */
      'pages/BracketChallenge/BracketChallengePage'
    ),
);
const BCGDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "BCGDashboardPage" */
      /* webpackPrefetch: true */
      'pages/BCGDashboard/BCGDashboardPage'
    ),
);
const SettingsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SettingsPage" */
      /* webpackPrefetch: true */
      'pages/Settings/SettingsPage'
    ),
);
const SettingsSupportPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SettingsSupportPage" */
      /* webpackPrefetch: true */
      'pages/SettingsSupport/SettingsSupportPage'
    ),
);
const SettingsCCPAPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SettingsCCPAPage" */
      /* webpackPrefetch: true */
      'pages/SettingsCCPA/SettingsCCPAPage'
    ),
);
const SettingsW2WPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SettingsW2WPage" */
      /* webpackPrefetch: true */
      'pages/SettingsW2W/SettingsW2WPage'
    ),
);
const VideoLandingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "VideoLandingPage" */
      /* webpackPrefetch: true */
      'pages/VideoLandingPage/VideoLandingPage'
    ),
);
const GameCenterPage = lazy(
  () =>
    import(
      /* webpackChunkName: "GameCenterPage" */
      /* webpackPrefetch: true */
      'pages/GameCenter/GameCenterPage'
    ),
);
const VODLandingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "VODLandingPage" */
      /* webpackPrefetch: true */
      'pages/VODLandingPage/VODLandingPage'
    ),
);
const SelectProviderPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SelectProviderPage" */
      /* webpackPrefetch: true */
      'pages/Auth/SelectProviderPage'
    ),
);
const LogoutProviderPage = lazy(
  () =>
    import(
      /* webpackChunkName: "LogoutProviderPage" */
      /* webpackPrefetch: true */
      'pages/Auth/LogoutProviderPage'
    ),
);
const BlankPage = lazy(
  () =>
    import(
      /* webpackChunkName: "BlankPage" */
      /* webpackPrefetch: true */
      'pages/Blank/BlankPage'
    ),
);

type LoadableRoute = {
  Component: LoadableComponent<unknown> | LoadableComponent<RouteProps>;
  props: RouteProps;
};

type LoadableRoutes = { [pathName: string]: LoadableRoute };

/**
 * This might seem unnecessary at first.  Why not just add all the routes into the <Switch/> component?
 * However what this allows us to do is have a single point of reference for all of our routes.
 * It's helpful to have these exported so that we can do things like preloading files on a per route basis.
 */
export const loadableRoutes: LoadableRoutes = {
  '/': {
    Component: HomePage,
    props: {},
  },
  '/bracket': {
    Component: BracketPage,
    props: {},
  },
  '/bcgbracket/:id': {
    Component: BCGBracketPage,
    props: {},
  },
  '/scores': {
    Component: ScoreboardPage,
    props: {},
  },
  '/bracketchallenge': {
    Component: BracketChallengePage,
    props: {},
  },
  '/bcgdashboard': {
    Component: BCGDashboardPage,
    props: {},
  },
  '/settings/*': {
    Component: SettingsPage,
    props: {},
  },
  '/privacy': {
    Component: SettingsSupportPage,
    props: {},
  },
  '/donotsell': {
    Component: SettingsCCPAPage,
    props: {},
  },
  '/waystowatch': {
    Component: SettingsW2WPage,
    props: {},
  },
  '/video/:type/:bracketId/:supressed?': {
    Component: VideoLandingPage,
    props: {},
  },
  '/game/:bracketId/video/:type': {
    Component: VideoLandingPage,
    props: {},
  },
  '/vod': {
    Component: VODLandingPage,
    props: {},
  },
  '/video/:type': {
    Component: VODLandingPage,
    props: {},
  },
  '/channel/:channelId/vod/:vodUrl': {
    Component: VODLandingPage,
    props: {},
  },
  '/video/:type/:bracketId': {
    Component: VideoLandingPage,
    props: {},
  },
  '/:videoCenterType/:bracketId': {
    Component: GameCenterPage,
    props: {},
  },
  '/selectprovider': {
    Component: SelectProviderPage,
    props: {},
  },
  '/logoutprovider': {
    Component: LogoutProviderPage,
    props: {},
  },
  '/blankpage': {
    Component: BlankPage,
    props: {},
  },
};

export const redirects: { to: string; from: string }[] = [
  { from: '/official_bracket', to: '/bracket' },
  { from: '/picks', to: '/bracketchallenge' },
  { from: '/bcg', to: '/bracketchallenge' },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = logger.getLogger('Routes');

export interface RouteData {
  bracketId?: string;
  supressed?: string;
  type?: VideoLinkType;
}

export const RoutesPath = (): JSX.Element => (
  <Routes>
    {redirects.map(({ from, to }) => (
      <Route key={from} element={<Navigate to={to} replace />} />
    ))}
    {/* Map over the configured routes */}
    {Object.entries(loadableRoutes).map(([path, { Component, props }]) => (
      <Route key={path} {...props} path={path} element={<Component />} />
    ))}
    <Route element={<Navigate to="/" replace />} />
  </Routes>
);

export default RoutesPath;
