export enum FocusKeys {
  AppContainer = 'APP_CONTAINER',
  AppUpdateDialog = 'APP_UPDATE_DIALOG',
  BCGChangeBracketButton = 'BCG_CHANGE_BRACKET_BUTTON',
  BelowInlinePlayerSection = 'BELOW_INLINE_PLAYER_SECTION',
  CcButton = 'CC_BUTTON',
  CcpaConfirmModal = 'CCPA_CONFIRM_MODAL',
  CcpaOptIn = 'CCPA_OPT_IN',
  ConnectedScrubber = 'CONNECTED_SCRUBBER',
  ContinuousPlayModal = 'CONTINUOUS_PLAY_MODAL',
  ContinuousPlayModalCancel = 'CONTINUOUS_PLAY_CANCEL_BUTTON',
  ContinuousPlayModalNextButton = 'CONTINUOUS_PLAY_UP_NEXT_BUTTON',
  DebugDialog = 'DEBUG_DIALOG',
  DebugDialogTopTabs = 'DEBUG_DIALOG_TOP_TABS',
  DebugFeatureSections = 'DEBUG_FEATURE_SECTIONS',
  DebugSwitchGameIds = 'DEBUG_SWITCH_GAME_IDS',
  DebugSwitchVideoOverlay = 'DEBUG_SWITCH_VIDEO_OVERLAY',
  FeatureDebugOptions = 'FEATURE_DEBUG_OPTIONS',
  FreePreviewBar = 'FREE_PREVIEW_BAR',
  FreePreviewDismiss = 'FREE_PREVIEW_DISMISS',
  FreePreviewSignInButton = 'FREE_PREVIEW_SIGN_IN_BUTTON',
  FullScreenButton = 'FULL_SCREEN_BUTTON',
  FullscreenExcitementAlert = 'FULLSCREEN_EXCITEMENT_ALERT',
  FullscreenExcitementAlertButton = 'FULLSCREEN_EXCITEMENT_ALERT_BUTTON',
  GameCenter = 'GAME_CENTER',
  GameCenterMiddleRow = 'GAME_CENTER_MIDDLE_ROW',
  GameCenterTopRow = 'GAME_CENTER_TOP_ROW',
  GetAccessModal = 'GET_ACCESS_MODAL',
  LoadingRetryButton = 'LOADING_RETRY_BUTTON',
  MessageModal = 'MESSAGE_MODAL',
  MessageModalDismiss = 'MESSAGE_MODAL_DISMISS',
  MultiGameAddButton = 'MULTI_GAME_ADD_BUTTON',
  MultiGameDebugExample = 'MULTIGAME_DEBUG_EXAMPLE',
  MultigameModal = 'MULTIGAME_MODAL',
  MultigameModalMultiGame = 'MULTIGAME_MODAL_MULTI_GAME',
  MultigameModalSingleGame = 'MULTIGAME_MODAL_SINGLE_GAME',
  PageContentContainer = 'PAGE_CONTENT_CONTAINER',
  SettingsAuthButton = 'SETTINGS_AUTH_BUTTON',
  SettingsBcgAuthButton = 'SETTINGS_BCG_AUTH_BUTTON',
  SettingsCcpaButton = 'SETTINGS_CCPA_BUTTON',
  SettingsDebugButton = 'SETTINGS_DEBUG_BUTTON',
  SettingsDebugResetButton = 'SETTINGS_DEBUG_RESET_BUTTON',
  SettingsSupportButton = 'SETTINGS_SUPPORT_BUTTON',
  SettingsW2WButton = 'SETTINGS_W2W_BUTTON',
  SettingsW2WMMLButton = 'SETTINGS_W2WMML_BUTTON',
  SettingsW2WMaxButton = 'SETTINGS_W2WMAX_BUTTON',
  SettingsW2WCBSButton = 'SETTINGS_W2WCBS_BUTTON',
  SideNav = 'SIDE_NAV',
  StoryViewerPanel = 'STORY_VIEWER_PANEL',
  TvProviderAuthSignOutButton = 'TV_PROVIDER_AUTH_SIGN_OUT_BUTTON',
  VideoControlsContainer = 'VIDEO_CONTROLS_CONTAINER',
  VideoControlsModalPlayPauseButton = 'VIDEO_CONTROLS_MODAL_PLAY_PAUSE_BUTTON',
  VodCarouselContainer = 'VOD_CAROUSEL_CONTAINER',
  VodContainer = 'VOD_CONTAINER',
  VodControlsContainer = 'VOD_CONTROLS_CONTAINER',
  VodControlsModalReplayButton = 'VOD_CONTROLS_MODAL_REPLAY_BUTTON',
  VodReplayButton = 'VOD_REPLAY_BUTTON',
  VodUpNextModal = 'VOD_UP_NEXT_MODAL',
}

// List of components that should have focus priority,
// in order from highest priority to lowest
export const FOCUS_PRIORITY_KEYS = [
  FocusKeys.AppUpdateDialog,
  FocusKeys.MessageModal,
  FocusKeys.FreePreviewBar,
  FocusKeys.ContinuousPlayModal,
];

type FocusKeyId = null | undefined | string | number;

export const getBCGUserBracketItemKey = (id: FocusKeyId) => `BCG_USER_BRACKET_ITEM_${id}` as const;

export const getBracketZoomNavItemKey = (id: FocusKeyId) => `BRACKET_ZOOM_NAV_ITEM_${id}` as const;

export const getDebugDialogEnvKey = (id: FocusKeyId) => `DEBUG_DIALOG_ENV_${id}` as const;

export const getDebugDialogTopTabKey = (id: FocusKeyId) => `DEBUG_DIALOG_TOP_TAB_${id}` as const;

export const getGameCenterLinkKey = (id: FocusKeyId) => `GAME_CENTER_LINK_${id}` as const;

export const getHomeCarouselItemKey = (rowIndex: FocusKeyId, tileIndex: FocusKeyId) =>
  `CAROUSEL${rowIndex}_TILE${tileIndex}` as const;

export const getHomeFeaturedCarouselKey = (contentType: FocusKeyId, contentIndex: FocusKeyId) =>
  `HOME_FEATURED_CAROUSEL_${contentType ?? ''}_${contentIndex}` as const;

export const getHomeGameCarouselKey = (id: FocusKeyId) => `HOME_GAME_CAROUSEL_${id}` as const;

export const getHomeVodCarouselKey = (id: FocusKeyId) => `HOME_VOD_CAROUSEL_${id}` as const;

export const getMarqueeKey = (id: FocusKeyId) => `MARQUEE_${id}` as const;

export const getMultiGameActionButtonKey = (playerSide: string, buttonSide: string) =>
  `${playerSide.toUpperCase()}_PLAYER_${buttonSide.toUpperCase()}_BUTTON` as const;

export const getNavItemKey = (id: FocusKeyId) => `NAV_ITEM_${id}` as const;

export const getScoreboardTilesContainerKey = (roundNumber: FocusKeyId, dayNumber: FocusKeyId) =>
  `SCOREBOARD_TILES_CONTAINER${
    roundNumber ? `_ROUND_${roundNumber}${dayNumber ? `_DAY_${dayNumber}` : ''}` : ''
  }` as const;

export const getStoryBookButtonKey = (id: FocusKeyId) => `STORY_BOOK_BUTTON_${id}` as const;

export const getStoryButtonKey = (parentStoryName: FocusKeyId, label: FocusKeyId) =>
  `STORY_BUTTON_${parentStoryName}_${label}` as const;

export const getStoryViewerPanelButtonKey = (id: FocusKeyId) => `STORY_VIEWER_PANEL_BUTTON_${id}` as const;

export const getDebugButtonKey = (id: FocusKeyId) => `DEBUG_BUTTON_${id}` as const;

export const getVideoPlayerKey = (id: FocusKeyId) => `VIDEO_PLAYER_${id}` as const;

export const getVodUpNextModalButtonKey = (id: FocusKeyId) => `${FocusKeys.VodUpNextModal}_${id}` as const;
