import { default as monitor } from 'utils/monitor';
import { logger } from 'logger';
import { WebOSApi } from './webOSApi';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = logger.getLogger('webOSProdApi');

enum MethodType {
  GET_IDS = 'deviceid/getIDs',
}

function getDeviceId(): Promise<string> {
  const idTypeName = 'LGUDID';
  return new Promise((resolve, reject) => {
    if (self.webOS) {
      try {
        self.webOS.service.request('luna://com.webos.service.sm', {
          method: MethodType.GET_IDS,
          parameters: {
            idType: [idTypeName],
          },
          onSuccess: function (inResponse) {
            LOG.info('DeviceID Response: ' + JSON.stringify(inResponse));
            resolve(inResponse.idList.find(({ idType }) => idType === idTypeName)?.idValue || '');
          },
          onFailure: function (inError) {
            const errorMessage = `Failed to get system ID information\n[${inError.errorCode}]: ${inError.errorText}`;
            LOG.error(errorMessage);
            monitor.sendError(errorMessage);
            reject({ errorCode: inError.errorCode, errorText: inError.errorText });
          },
        });
      } catch (error) {
        const errorMessage = `DeviceID service call failed ${error}`;
        monitor.sendError(errorMessage);
      }
    } else {
      reject({ errorText: 'webOSProdApi not defined' });
    }
  });
}

function getDeviceInfo(): Promise<DeviceInfo> {
  // lazy way of retrying when we get invalid data back from their sdk
  return _getDeviceInfo()
    .catch(() => delayedPromise(1000))
    .then(() => _getDeviceInfo())
    .catch(() => delayedPromise(1000))
    .then(() => _getDeviceInfo())
    .catch(() => delayedPromise(1000))
    .then(() => _getDeviceInfo());
}

function delayedPromise(delayTimeInMs: number): Promise<void> {
  return new Promise<void>((resolve) => setTimeout(resolve, delayTimeInMs));
}

function _getDeviceInfo(): Promise<DeviceInfo> {
  return new Promise<DeviceInfo>((resolve, reject) => {
    if (self.webOS) {
      try {
        self.webOS.deviceInfo((deviceInfo) => {
          LOG.info('Device Info: ' + JSON.stringify(deviceInfo));

          if (deviceInfo.sdkVersion !== deviceInfo.version) {
            resolve(deviceInfo);
          } else {
            // The webos sdk has a habit of returning the wrong information the first/second time you query
            // for the device info.  Or if you query in a loop with no delay, it'll return the wrong information
            // until something crashes on the tv itself.
            const errorMessage = 'DeviceInfo is invalid! (sdk version matches firmware version)';
            LOG.error(errorMessage);
            monitor.sendError(errorMessage);
            reject(errorMessage);
          }
        });
      } catch (error) {
        const errorMessage = `DeviceInfo call failed ${error}`;
        monitor.sendError(errorMessage);
        reject(errorMessage);
      }
    } else {
      reject({ errorText: 'webOSProdApi not defined' });
    }
  });
}

function getSystemCaptionsEnabled() {
  return new Promise<boolean | null>((resolve, reject) => {
    if (!self.webOS) return reject({ errorText: 'webOSProdApi not defined' });

    try {
      self.webOS.service.request('luna://com.webos.settingsservice', {
        method: 'getSystemSettings',
        parameters: {
          category: 'caption',
          keys: ['captionEnable'],
        },
        onSuccess: function (response) {
          resolve(response.settings.captionEnable === 'on');
        },
        onFailure: function (response) {
          const errorMessage = `Failed to get system caption settings information\n[${response.errorCode}]: ${response.errorText}`;
          LOG.error(errorMessage);
          monitor.sendError(errorMessage);
          return reject(response);
        },
      });
    } catch (error) {
      const errorMessage = `System Captions call failed ${error}`;
      LOG.error(errorMessage);
      monitor.sendError(errorMessage);
      reject(errorMessage);
    }
  });
}

function launchApp(parameters: { id: string; params?: { query: string } }) {
  return new Promise<boolean | null>((resolve, reject) => {
    if (!self.webOS) return reject({ errorText: 'webOSProdApi not defined' });

    try {
      self.webOS.service.request('luna://com.webos.applicationManager', {
        method: 'launch',
        parameters,
        onSuccess: function (response) {
          resolve(response.returnValue);
        },
        onFailure: function (response) {
          const errorMessage = `Failed to launch app\n[${response.errorCode}]: ${response.errorText}`;
          LOG.error(errorMessage);
          monitor.sendError(errorMessage);
          return reject(response);
        },
      });
    } catch (error) {
      const errorMessage = `Launch app call failed ${error}`;
      LOG.error(errorMessage);
      monitor.sendError(errorMessage);
      reject(errorMessage);
    }
  });
}

export const webOSApi: WebOSApi = {
  getDeviceId,
  getDeviceInfo,
  getSystemCaptionsEnabled,
  launchApp,
};
