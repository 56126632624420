type Arg = string | number | boolean | undefined | null | Record<string, unknown> | Arg[];

/**
 * This little utility makes it easy to conditionally add classNames to a dom element
 * without having to do a ton of yucky string interpolation.
 *
 * @param args a string or object or array of objects that can be interpreted as a className
 * @returns a string that can be used as the `className` attribute on any dom node.
 *
 * @example
 * // the problem
 * return <div className={`button ${isActive ? 'active' : ''} ${isFocused ? 'focused' : ''}`}>Click Me!</div>
 *
 * // the solution
 * const buttonClassName = classNames('button', { active: isActive, focused: isFocused })
 * return <div className={buttonClassName}>Click Me!</div>
 *
 * // or inline
 * return <div className={classNames('button', { active: isActive, focused: isFocused })}>Click Me!</div>
 */
export default function classNames(...args: Arg[]) {
  const classes: (string | number)[] = [];

  for (const arg of args) {
    if (!arg) continue;

    if (typeof arg === 'string' || typeof arg === 'number') {
      classes.push(arg);
    } else if (Array.isArray(arg)) {
      if (arg.length) {
        classes.push(classNames(...arg));
      }
    } else if (typeof arg === 'object') {
      if (arg.toString === Object.prototype.toString) {
        Object.entries(arg).forEach(([key, val]) => {
          if (val) classes.push(key);
        });
      } else {
        classes.push(arg.toString());
      }
    }
  }

  return classes.filter(Boolean).join(' ');
}
