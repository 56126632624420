import { useEffect, useRef } from 'react';
import { matchPath } from 'react-router-dom';
import { loadableRoutes } from 'routes';

export function usePreloadRoute(): void {
  const numRenders = useRef(0);

  useEffect(() => {
    if (numRenders.current === 0) {
      const [, pathName = '/'] = self.location.hash.split('#');

      const [, loadableComponent] =
        Object.entries(loadableRoutes).find(([path, { props }]) => matchPath({ ...props, path }, pathName)) ?? [];

      loadableComponent?.Component.preload();

      numRenders.current += 1;
    }
  }, []);
}
