"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SCTESpliceCommandType = exports.MockMessageType = exports.MockMessageTriggerName = void 0;
var MockMessageTriggerName;
(function (MockMessageTriggerName) {
    MockMessageTriggerName["Content_Time"] = "model.contentTime";
    MockMessageTriggerName["Media_Time"] = "model.mediaTime";
    MockMessageTriggerName["Ad_Time"] = "model.adTime";
})(MockMessageTriggerName = exports.MockMessageTriggerName || (exports.MockMessageTriggerName = {}));
var MockMessageType;
(function (MockMessageType) {
    MockMessageType["Timed_Metadata"] = "timedMetadata";
})(MockMessageType = exports.MockMessageType || (exports.MockMessageType = {}));
/**
 * Identifies the type of splice command, maps to `type` of SCTESpliceCommand
 */
var SCTESpliceCommandType;
(function (SCTESpliceCommandType) {
    SCTESpliceCommandType[SCTESpliceCommandType["Null"] = 0] = "Null";
    SCTESpliceCommandType[SCTESpliceCommandType["Schedule"] = 4] = "Schedule";
    SCTESpliceCommandType[SCTESpliceCommandType["Insert"] = 5] = "Insert";
    SCTESpliceCommandType[SCTESpliceCommandType["Time_Signal"] = 6] = "Time_Signal";
    SCTESpliceCommandType[SCTESpliceCommandType["Bandwidth_Reservation"] = 7] = "Bandwidth_Reservation";
    SCTESpliceCommandType[SCTESpliceCommandType["Private"] = 255] = "Private";
})(SCTESpliceCommandType = exports.SCTESpliceCommandType || (exports.SCTESpliceCommandType = {}));
