import log from 'loglevel';
import prefix from 'loglevel-plugin-prefix';
import { isProdDomain } from '../utils/assets';
import { debugEnabled, isWebWorker } from '../utils/dom-utils';

prefix.reg(log);

const isProd = isProdDomain();
const enableLogs = debugEnabled();
if (isProd && !enableLogs) {
  log.disableAll();
} else {
  log.enableAll();
}

prefix.apply(log, {
  template: '%l [%n]',

  levelFormatter(level: string) {
    return level.toUpperCase();
  },
  nameFormatter(name: string | undefined) {
    return name || 'global';
  },
});

export const logger = log;

if (!isWebWorker()) {
  self.PWA_LOGGER = logger;
}

if (process.env.JEST_WORKER_ID !== undefined || process.env.NODE_ENV === 'test') logger.disableAll();
