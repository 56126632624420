/* eslint-disable @typescript-eslint/no-explicit-any */
import { AdBreak, AdBreakMetadata } from 'video-api';
import { logger } from 'logger';

declare global {
  interface Window {
    newrelic: any;
  }
}

const LOG = logger.getLogger('newRelicMonitor');

export type CustomAttribute = 'webosVersion' | 'modelName' | 'apiLevel' | 'modelVersion';

export type VideoErrorMetadata = {
  bitrate: number | undefined;
  frameRate: number | undefined;
  adBreaks: AdBreakMetadata[];
  segmentURL: string;
  currentAdBreak: AdBreak | null;
  currentAdId: string;
  resolution: string;
  mvpd: string;
  freeViewTimeRemaining: number | null | undefined;
  segmentPlaybackUid: string | null | undefined;
  segmentPlaybackMimetype: string | null | undefined;
  segmentPlaybackPlaybackTime: number | null | undefined;
  segmentPlaybackWallClockTime: number | null | undefined;
  segmentPlaybackPresentationTimestamp: number | null | undefined;
  segmentPlaybackDuration: number | null | undefined;
  segmentPlaybackMediaInfoBitrate: number | null | undefined;
  segmentPlaybackMediaInfoFramerate: number | null | undefined;
  segmentPlaybackMediaInfoHeight: number | null | undefined;
  segmentPlaybackMediaInfoWidth: number | null | undefined;
  segmentPlaybackMediaInfoSampleRate: number | null | undefined;
};

export const Monitor = {
  sendError(err: string | Error): void {
    if (typeof window != null && typeof self.newrelic !== undefined) {
      if (typeof err === 'string') {
        window?.newrelic?.noticeError(new Error(err));
      } else {
        window?.newrelic?.noticeError(err);
      }
    }
  },
  sendVideoError(err: string | Error, errorMetadata: object): void {
    if (typeof window != null && typeof self.newrelic !== undefined) {
      if (errorMetadata) {
        window?.newrelic?.addCustomAttributes(errorMetadata);
      }
      if (typeof err === 'string') {
        window?.newrelic?.noticeError(new Error(err));
      } else {
        window?.newrelic?.noticeError(err);
      }
    }
  },
  addToTrace(eventName: string, metadata: object): void {
    if (typeof window != null && typeof self.newrelic !== undefined) {
      window?.newrelic?.addToTrace?.({
        name: eventName,
        start: Date.now(),
        origin: metadataToFormattedString(metadata),
      });
    }
  },
  addPersistentAttributes(key: CustomAttribute, value: string | null | undefined): void {
    if (typeof window != null && typeof self.newrelic !== undefined) {
      window?.newrelic?.setCustomAttribute(key, value);
    }
  },
  setReleaseVersion(releaseId: string): void {
    if (typeof window != null && typeof self.newrelic !== undefined) {
      window?.newrelic?.addRelease(self.location.host, releaseId);
    }
  },
};

function metadataToFormattedString(metadata: object): string {
  let formattedMetadata = '';
  try {
    for (const [key, value] of Object.entries(metadata)) {
      formattedMetadata += `${key}: ${value}\n`;
    }
  } catch (e) {
    LOG.error(e);
    if (e instanceof Error) {
      Monitor.sendError(e);
    }
  }
  return formattedMetadata;
}

export default Monitor;
