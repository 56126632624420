import { BCGDashboardInfo } from 'types/bcgTypes';

export type BCGState = {
  isLoggedIn: boolean;
  authToken: string;
  staticEndpointKey: string;
};

export const BCG_STATE_INITIAL: BCGState = {
  isLoggedIn: false,
  authToken: '',
  staticEndpointKey: '',
};

export type BCGUserInfo = BCGDashboardInfo;

export const BCG_USERINFO_INITIAL: BCGUserInfo = {
  user: null,
  mbcgEntries: [],
};
