import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getDevice, Device } from 'utils/getDevice';
interface Props {
  activate: (value: boolean) => void;
}

const device = getDevice();

export const useFocusApp = (): Props => {
  const triggerDirty = useRef(true);

  const [trigger, setTrigger] = useState<boolean>(false);

  const focusApp = useCallback(() => {
    try {
      console.log('[useFocusApp] running focus in app');
      document.body.tabIndex = 0;
      document.body.blur();
      document.body.focus();
      triggerDirty.current = false;
      if (device === Device.xbox) navigator.gamepadInputEmulation = 'keyboard';
    } catch (e) {
      console.log('[useFocusApp] could not focus');
    }
  }, []);

  useEffect(() => {
    if (triggerDirty.current) {
      setTimeout(() => {
        focusApp();
      }, 50);
    }
  }, [focusApp, trigger]);

  return useMemo(
    () => ({
      activate: setTrigger,
    }),
    [],
  );
};
