"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerError = void 0;
var PlayerError = /** @class */ (function () {
    function PlayerError(error) {
        this._error = {
            code: error.code,
            categoryCode: error.categoryCode,
            toString: error.toString,
        };
    }
    Object.defineProperty(PlayerError.prototype, "error", {
        get: function () {
            return this._error;
        },
        enumerable: false,
        configurable: true
    });
    return PlayerError;
}());
exports.PlayerError = PlayerError;
