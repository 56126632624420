import { logger } from 'logger';
import { useCallback } from 'react';
import { NavigationType, unstable_BlockerFunction as BlockerFunction, useBlocker } from 'react-router-dom';
import useInstanceCounter from './useInstanceCounter';
import historyService from 'services/history';
import { useSingleKeyComboTrigger } from './useKeyComboTrigger';
import { Device, getDevice } from 'utils/getDevice';
import { Keysandroidtv, Keysfiretv, Keyslgtv, Keysxbox } from 'utils/Keys';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = logger.getLogger('useGoBack');

const backKey = getDeviceKeys().BACK;
const device = getDevice();

declare global {
  interface Window {
    __backButton__?: () => void;
  }
}

/**
 * This hook ties the devices back button to the router goBack logic
 * as long as it is enabled.
 */
export function useGoBack(): void {
  const handleBackButton = useCallback(() => {
    historyService.go(-1);
  }, []);

  useSingleKeyComboTrigger([backKey], handleBackButton);

  if (process.env.NODE_ENV !== 'production') {
    /**
     * You can use this utility to simulate the back button from the browser console.
     */
    self.__backButton__ = () => {
      const keys = getDeviceKeys();
      const keydownEvent = new KeyboardEvent('keydown', { bubbles: true, cancelable: true, keyCode: keys.BACK });
      const keyupEvent = new KeyboardEvent('keyup', { bubbles: true, cancelable: true, keyCode: keys.BACK });

      self.dispatchEvent(keydownEvent);
      setTimeout(() => self.dispatchEvent(keyupEvent), 25);
    };
  }
}

/**
 * This hook makes it easy to fire a callback when the back button is pressed.
 * @param callback a function to execute when the device's back button is pressed.
 */
export function useBackButton(callback: () => boolean) {
  useBrowserBackButton(NavigationType.Pop, callback);
}

/**
 * This hook lets you block a history change and execute your own logic using a callback.
 * If the callback returns `true`, then the history transition will be blocked.
 *
 * FireTV doesn't dispatch a key event when the back button is pressed.
 * Instead it acts like pressing the browser back button.
 *
 * @param action the history action or array of actions to attempt to block.
 * @param callback a function to execute when the device's back button is pressed.  If it returns `true`, then the history transition will be blocked.
 */
function useBrowserBackButton(action: NavigationType | NavigationType[], callback: () => boolean) {
  const instanceCount = useInstanceCounter('useBrowserBackButton');

  const blockerFunction = useCallback<BlockerFunction>(
    ({ historyAction }) => {
      if (instanceCount > 1) return false;

      if (action === historyAction || (Array.isArray(action) && action.includes(historyAction))) {
        return callback();
      }
      return false;
    },
    [instanceCount, callback, action],
  );

  return useBlocker(blockerFunction);
}

export function getDeviceKeys() {
  switch (device) {
    case Device.xbox:
      return Keysxbox;
    case Device.androidtv:
      return Keysandroidtv;
    case Device.firetv:
      return Keysfiretv;
    case Device.lgtv:
      return Keyslgtv;
    default:
      LOG.error(`unhandled device type ${device}`);
      return Keysxbox;
  }
}
