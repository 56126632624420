let cachedIsWebos4: boolean | undefined = undefined;

export function isWebos4(webOSDeviceInfo: DeviceInfo | undefined | null): boolean {
  if (cachedIsWebos4 !== undefined) return cachedIsWebos4;
  // If we don't have the _webosDeviceInfo set at this point, assume the worst about the device specs
  if (!webOSDeviceInfo) return true;
  const segments = webOSDeviceInfo.sdkVersion.split('.');
  cachedIsWebos4 = +segments[0] === 4;
  return cachedIsWebos4;
}
