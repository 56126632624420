"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MocksHelpers = void 0;
/**
 * A helper utility for creating commonly used mock messages
 */
var MocksHelpers = /** @class */ (function () {
    function MocksHelpers() {
    }
    MocksHelpers.init = function (mocksHelpers) {
        MocksHelpers._mocksHelpers = mocksHelpers;
    };
    /**
     * Creates a mock message of type `Timed_Metadata`, with a payload containing
     * SCTE-specific metadata for a Chapter End segmentation descriptor
     */
    MocksHelpers.createChapterEnd = function (data) {
        if (!this._mocksHelpers)
            throw new Error('MocksHelpers must be initialized first');
        return this._mocksHelpers.createChapterEnd(data);
    };
    /**
     * Creates a mock message of type `Timed_Metadata`, with a payload containing
     * SCTE-specific metadata for a Program End segmentation descriptor
     */
    MocksHelpers.createProgramEnd = function (data) {
        if (!this._mocksHelpers)
            throw new Error('MocksHelpers must be initialized first');
        return this._mocksHelpers.createChapterEnd(data);
    };
    return MocksHelpers;
}());
exports.MocksHelpers = MocksHelpers;
