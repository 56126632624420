import { AndroidBridge } from 'android-bridge';
// import { logger } from 'logger';
// const LOG = logger.getLogger('InitAndroidBridge');

export const FIREOS_MEDIA_REWIND = 'FIREOS_MEDIA_REWIND_CE';
export const FIREOS_MEDIA_FAST_FORWARD = 'FIREOS_MEDIA_FAST_FORWARD_CE';
export const FIREOS_MEDIA_PLAY_PAUSE = 'FIREOS_MEDIA_PLAY_PAUSE_CE';

export const InitAndroidBridge = (): void => {
  const bridge = {} as AndroidBridge;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).AndroidBridge = bridge;
};
