import { FC, PropsWithChildren, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useSponsorAd, { Status, UseSponsorAdProps } from './useSponsorAd';
import { TaglessRequestResponse } from 'types/configs/ads-config';

export enum TrackersFrequency {
  OnScrollIntoViewOnce,
  OnEveryScrollIntoView,
}

export interface SponsorAdProps extends Omit<UseSponsorAdProps, 'shouldFireTrackersImmediately'> {
  fallback?: () => ReactElement | null;
  fireTrackersFrequency?: TrackersFrequency;
  hideIfEmptyTaglessRequest?: boolean;
  onAdLoaded?: () => void;
  onSponsorReady?: (sponsor?: TaglessRequestResponse | null) => void;
  description: string;
  /** This is a component where the children prop will be the rendered sponsor image */
  Wrapper?: FC<PropsWithChildren>;
}

const SponsorAd: FC<SponsorAdProps> = (props) => {
  const { fireTrackersFrequency = TrackersFrequency.OnScrollIntoViewOnce, onAdLoaded, onSponsorReady, Wrapper } = props;
  const [hasBeenInView, setHasBeenInView] = useState(false);
  const { sponsorAd, fireTrackers, status } = useSponsorAd({
    url: props.url,
    montageId: props.montageId,
    shouldFireTrackersImmediately: false,
  });

  const intersectionObserverCallback: IntersectionObserverCallback = useCallback(() => {
    switch (fireTrackersFrequency) {
      case TrackersFrequency.OnScrollIntoViewOnce: {
        if (!hasBeenInView) fireTrackers();
        break;
      }
      case TrackersFrequency.OnEveryScrollIntoView: {
        fireTrackers();
        break;
      }
    }
    setHasBeenInView(true);
  }, [fireTrackers, fireTrackersFrequency, hasBeenInView]);

  const intersectionObserver = useMemo(
    () => new IntersectionObserver(intersectionObserverCallback, { threshold: 0 }),
    [intersectionObserverCallback],
  );

  const imageRef = useRef<HTMLImageElement>(null);

  // The tech spec is wrong about this. We should only check for the presence of the image property
  const isTaglessRequestEmpty = status === Status.Completed && !sponsorAd?.image;

  useEffect(() => {
    if (status === Status.Completed) {
      onSponsorReady?.(sponsorAd);
    }
  }, [onSponsorReady, sponsorAd, status]);

  useEffect(() => {}, [sponsorAd, status]);

  useEffect(() => {
    if (sponsorAd?.image) {
      const imageElement = imageRef.current as HTMLImageElement;

      if (imageElement) {
        intersectionObserver.observe(imageElement);
      }
      return () => {
        if (imageElement) {
          intersectionObserver.unobserve(imageElement);
        }
      };
    }
    return;
  }, [intersectionObserver, sponsorAd?.image]);

  if (props.hideIfEmptyTaglessRequest && isTaglessRequestEmpty) return null;

  if (!sponsorAd?.image) {
    switch (status) {
      case Status.Completed:
      case Status.Error:
        return props.fallback?.() ?? null;
      default:
        return null;
    }
  }

  const imgElement = (
    <img
      ref={imageRef}
      src={sponsorAd.image}
      height={sponsorAd.image_h}
      width={sponsorAd.image_w}
      style={{ height: sponsorAd.display_h, width: sponsorAd.display_w }}
      alt={`Sponsor Ad (${props.description})`}
      onLoad={onAdLoaded}
    />
  );

  if (Wrapper) return <Wrapper>{imgElement}</Wrapper>;

  return imgElement;
};

export default SponsorAd;
