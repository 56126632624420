import styled from 'styled-components';

export const LoadingBarFullScreen = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  &::before {
    content: ' ';
    background-color: var(--color_lvl_-5);
    height: 100%;
    left: 0;
    opacity: 0.9;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const StyledLoadingBar = styled.div`
  width: 280px;
  height: 48px;
  margin: 0 auto;
  & > div {
    display: inline-block;
    width: 38px;
    height: 18px;
    margin: 16px;
    background: var(--color_primaryaccent_DKMD);
    animation: spinner 2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }

  @keyframes spinner {
    50%,
    80% {
      transform: scaleX(0.1);
    }

    0% {
      transform: scaleX(1);
    }
  }

  & div:nth-child(1) {
    animation-delay: -0.9s;
  }

  & div:nth-child(2) {
    animation-delay: -0.6s;
  }

  & div:nth-child(3) {
    animation-delay: -0.3s;
  }

  & div:nth-child(4) {
    animation-delay: 0s;
  }
`;
