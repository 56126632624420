import { CLIENT_SCHEMA } from 'apolloGraphql/schemas/client-schema';
import { MML_APOLLO_CACHE, videoSessionStore } from 'apolloGraphql/mml-apollo-cache';
import PropTypes from 'prop-types';
import { FC, PropsWithChildren } from 'react';
import { APP_VERSION } from 'version';
import { APP_NAME } from '../app-constants';
import { AppContext } from './app-context';
import { useContextSetup } from './useContextSetup';
import { InitializeApolloPersisted } from 'apolloGraphql/initialize-apollo-persisted';
import VideoSessionContext from './video-session-context';
import { ApolloCache, useReactiveVar } from '@apollo/client';
import useInitialScripts from '../hooks/useInitialScripts';
import type { MockedResponse } from '@apollo/client/testing';
import { AdsConfig, AppConfig, BillboardsConfig } from 'types/configs';

export type ContextProps = PropsWithChildren<{
  adsConfig?: AdsConfig | null | undefined;
  appConfig?: AppConfig | null | undefined;
  billboardsConfig?: BillboardsConfig | null | undefined;
  cache?: ApolloCache<Record<string, unknown>>;
  mocks?: MockedResponse[];
}>;

export const Context: FC<ContextProps> = (props) => {
  useInitialScripts();
  const {
    adsConfig,
    appConfig,
    appConfigUrl,
    appControlsFetchCounter,
    appControlsPollingEnabled,
    billboardsConfig,
    boxscoreFetchCounter,
    boxscorePollingEnabled,
    currentFetchCounter,
    currentPollingEnabled,
    device,
    setAdsConfig,
    setAppConfig,
    setAppConfigUrl,
    setAppControlsFetchCounter,
    setAppControlsPollingEnabled,
    setBillboardsConfig,
    setBoxscoreFetchCounter,
    setBoxscorePollingEnabled,
    setCurrentFetchCounter,
    setCurrentPollingEnabled,
    setWebOSDeviceInfo,
    webosDeviceInfo,
  } = useContextSetup();
  const { primarySession } = useReactiveVar(videoSessionStore);

  return (
    <AppContext.Provider
      value={{
        device,
        appConfigUrl,
        appConfig,
        adsConfig,
        billboardsConfig,
        webosDeviceInfo,
        currentPollingEnabled: currentPollingEnabled,
        boxscorePollingEnabled: boxscorePollingEnabled,
        appControlsPollingEnabled: appControlsPollingEnabled,
        currentFetchCounter: currentFetchCounter,
        boxscoreFetchCounter: boxscoreFetchCounter,
        appControlsFetchCounter: appControlsFetchCounter,
        incrementCurrentFetchCounter: () => setCurrentFetchCounter((current) => ++current),
        incrementBoxscoreFetchCounter: () => setBoxscoreFetchCounter((current) => ++current),
        incrementAppControlsFetchCounter: () => setAppControlsFetchCounter((current) => ++current),
        setCurrentPollingEnabled: setCurrentPollingEnabled,
        setBoxscorePollingEnabled: setBoxscorePollingEnabled,
        setAppControlsPollingEnabled: setAppControlsPollingEnabled,
        setAppConfigUrl: setAppConfigUrl,
        setAppConfig: setAppConfig,
        setAdsConfig: setAdsConfig,
        setBillboardsConfig: setBillboardsConfig,
        setWebOSDeviceInfo: setWebOSDeviceInfo,
      }}
    >
      <VideoSessionContext.Provider value={primarySession}>
        <InitializeApolloPersisted
          uri={appConfig?.gql.host || ''}
          appVersion={APP_VERSION.version}
          appName={APP_NAME}
          cache={MML_APOLLO_CACHE}
          typeDefs={CLIENT_SCHEMA}
        >
          {props.children}
        </InitializeApolloPersisted>
      </VideoSessionContext.Provider>
    </AppContext.Provider>
  );
};

Context.propTypes = {
  children: PropTypes.node.isRequired,
};
