import React, { FC } from 'react';
import { SponsorAdGroup } from 'components/SponsorAd/SponsorAdGroup';
import { AppSponsorAdContainer } from './styles';
import { useAppContext } from 'context/app-context';

const AppPresentedBarContentMemoized: FC<{ adUrls: string[] }> = (props) => {
  return (
    <SponsorAdGroup
      ads={props.adUrls}
      description="app_sponsor"
      revealWithAdLoaded={{
        outside: () => {
          return <div className="ConnSubtitle-3LVL-5">APP PRESENTED BY</div>;
        },
      }}
      Wrapper={({ children }) => <AppSponsorAdContainer>{children}</AppSponsorAdContainer>}
    />
  );
};

const AppPresentedBarContent = React.memo(AppPresentedBarContentMemoized);

const AppPresentedBar: FC = () => {
  const { adsConfig } = useAppContext();

  return adsConfig?.connected.tracker_request_urls ? (
    <AppPresentedBarContent adUrls={adsConfig?.connected.tracker_request_urls.loading_screens ?? []} />
  ) : null;
};

export default AppPresentedBar;
