import { useEffect, useRef } from 'react';

export default function useEventListener<K extends keyof HTMLElementEventMap>(
  eventName: K,
  handler: (event: HTMLElementEventMap[K]) => void,
  element: HTMLElement | null,
): void;
export default function useEventListener<K extends keyof WindowEventMap>(
  eventName: K,
  handler: (event: WindowEventMap[K]) => void,
  element?: Window & typeof globalThis,
): void;
export default function useEventListener<K extends keyof (HTMLElementEventMap & WindowEventMap)>(
  eventName: K,
  handler: (event: (HTMLElementEventMap & WindowEventMap)[K]) => void,
  element: HTMLElement | null | (Window & typeof globalThis) = window,
) {
  const savedHandler = useRef<typeof handler>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const eventListener = ((event: (HTMLElementEventMap & WindowEventMap)[K]) =>
      savedHandler.current?.(event)) as EventListenerOrEventListenerObject;

    element?.addEventListener(eventName, eventListener);

    return () => {
      element?.removeEventListener(eventName, eventListener);
    };
  }, [element, eventName]);
}
