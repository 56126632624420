import { videoSessionStore } from 'apolloGraphql/mml-apollo-cache';
import { createContext, useContext } from 'react';

import { SessionName } from 'state-machines/video-session-state';

const VideoSessionContext = createContext<SessionName>(videoSessionStore().primarySession);
VideoSessionContext.displayName = 'VideoSessionContext';
export default VideoSessionContext;

export function useVideoSession() {
  return useContext(VideoSessionContext);
}
