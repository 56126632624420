import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import useEventListener from './useEventListener';

export interface KeyCombo {
  keys: Array<number>;
  setValue: Dispatch<SetStateAction<boolean>>;
}

export const useKeyComboTrigger = (keyComboList: KeyCombo[]): void => {
  const keysPressed = useRef<number[]>([]);

  const handleUserKeyPress = useCallback(
    (event: KeyboardEvent): void => {
      const { keyCode, type } = event;

      keysPressed.current =
        type === 'keydown'
          ? [...keysPressed.current, keyCode]
          : keysPressed.current.filter((item: number) => item !== keyCode);

      keyComboList.forEach((combo) => {
        const input = keysPressed.current.reduce((a: number, b: number) => a + b, 0);
        const truth = combo.keys.reduce((a: number, b: number) => a + b, 0);
        if (truth === input && type === 'keydown') {
          combo.setValue((state) => !state);
          keysPressed.current = [];
        }
      });
    },

    [keyComboList],
  );

  useEffect(() => {
    //handle keypress
    self.addEventListener('keydown', handleUserKeyPress);
    self.addEventListener('keyup', handleUserKeyPress);

    return (): void => {
      self.removeEventListener('keydown', handleUserKeyPress);
      self.removeEventListener('keyup', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);
};

type KeyList = KeyboardEvent['keyCode'][];

/**
 * Given an array of `keyCodes`, this hook will return true if all of the corresponding keys
 * are simultaneously being pressed.  I like this pattern more than the useKeyComboTrigger hook
 * because it doesn't rely on the component to manage state.  This hook handles it by itself.
 * More than one `useSingleKeyComboTrigger` can be used in a single component.
 */
export const useSingleKeyComboTrigger = (keyCombo: KeyList, memoizedCallback?: () => void) => {
  const keysPressed = useRef<KeyList>([]);
  const [comboTriggered, setComboTriggered] = useState(false);

  const handleUserKeyDown = useCallback(
    (event: KeyboardEvent) => {
      keysPressed.current.push(event.keyCode);
      setComboTriggered(isArrayItemsInArray(keyCombo, keysPressed.current));
    },
    [keyCombo],
  );

  const handleUserKeyUp = useCallback(
    (event: KeyboardEvent) => {
      keysPressed.current = keysPressed.current.filter((key) => key !== event.keyCode);
      setComboTriggered(isArrayItemsInArray(keyCombo, keysPressed.current));
    },
    [keyCombo],
  );

  useEventListener('keydown', handleUserKeyDown);
  useEventListener('keyup', handleUserKeyUp);

  useEffect(() => {
    if (comboTriggered) memoizedCallback?.();
  }, [comboTriggered, memoizedCallback]);

  return comboTriggered;
};

function isArrayItemsInArray<T>(target: T[], compare: T[]) {
  return target.every((item) => compare.includes(item));
}
