import { useEffect, useState } from 'react';

function navigatorOnline(): boolean {
  return typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean' ? navigator.onLine : true;
}
export function useIsOnline(): boolean {
  const [networkStatus, setOnlineStatus] = useState(navigatorOnline());

  const setOnline = (): void => setOnlineStatus(true);
  const setOffline = (): void => setOnlineStatus(false);

  useEffect(() => {
    self.addEventListener('online', setOnline);
    self.addEventListener('offline', setOffline);

    return (): void => {
      self.removeEventListener('online', setOnline);
      self.removeEventListener('offline', setOffline);
    };
  }, []);

  return networkStatus;
}
