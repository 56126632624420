import { useCallback, useEffect, useRef } from 'react';

/**
 * # usePollInterval
 * This hook is designed to make it easy to execute a given callback on a given interval.
 * It also handles all cleanup.
 * @param callback a callback to execute at a given interval
 * @param intervalInMilliseconds the interval in milliseconds to execute the callback on
 * @returns
 */
export default function usePollInterval(
  callback: () => void,
  intervalInMilliseconds: number | undefined,
  executeImmediately = false,
) {
  const timeoutRef = useRef<NodeJS.Timeout>();

  const clearCallbackTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }, []);

  const execute = useCallback(() => {
    clearCallbackTimeout();

    callback();

    if (intervalInMilliseconds && intervalInMilliseconds > 0) {
      timeoutRef.current = setTimeout(execute, intervalInMilliseconds);
    }
  }, [callback, clearCallbackTimeout, intervalInMilliseconds]);

  useEffect(() => {
    if (executeImmediately) execute();
  }, [execute, executeImmediately]);

  useEffect(() => {
    return () => {
      clearCallbackTimeout();
    };
  }, [clearCallbackTimeout]);

  return { execute, clear: clearCallbackTimeout };
}
