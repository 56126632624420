import React from 'react';
import { LoadingBarFullScreen, StyledLoadingBar } from './styles';

const LoadingBar = ({ isFullScreen }: { isFullScreen?: boolean }): JSX.Element => {
  if (isFullScreen) {
    return (
      <LoadingBarFullScreen>
        <InternalLoadingBar />
      </LoadingBarFullScreen>
    );
  }
  return <InternalLoadingBar />;
};

const InternalLoadingBar = (): JSX.Element => {
  return (
    <StyledLoadingBar>
      <div />
      <div />
      <div />
      <div />
    </StyledLoadingBar>
  );
};
export default LoadingBar;
