import { MarqueeState, MarqueeStateNames, MarqueeStates } from './marquee-states';
import { StatSectionStates, StatsSectionState, StatsSectionStateNames } from './stats-section-states';

export type EventCenterState = {
  bracketId: number;
  marquee: MarqueeState;
  statsSection: StatsSectionState;
  gameswitcherSection: boolean;
  noLiveGames: boolean;
};

export const EVENT_CENTER_STATE_INITIAL: EventCenterState = {
  bracketId: -1,
  marquee: MarqueeStates[MarqueeStateNames.UPCOMING],
  statsSection: StatSectionStates[StatsSectionStateNames.UPCOMING],
  gameswitcherSection: false,
  noLiveGames: true,
};
