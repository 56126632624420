export type GameCenterReRoute = {
  sideNavPathname: string;
};

export type AuthReRoute = {
  authReRoutePathname: string;
};

export const GAMECENTER_RE_ROUTE_STATE_INITIAL: GameCenterReRoute = {
  sideNavPathname: '',
};

export const AUTH_RE_ROUTE_STATE_INITIAL: AuthReRoute = {
  authReRoutePathname: '',
};
