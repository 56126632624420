import {
  basePropertyStore,
  customPropertyStore,
  externalCampaignStore,
  pageInfoState,
  persistentPropertyStore,
  userPropertyStore,
} from 'apolloGraphql/mml-apollo-cache';
import { logger } from 'logger';
import type { CustomPropertyType } from 'state-machines/analytic-states';
import { getAnonymousId, getMarketingCloudVisitorID } from './heartbeatWrapper';
import { SessionName, updateVideoSession } from 'state-machines/video-session-state';
import { getDevice } from './getDevice';
import { AppWorkerFactoryInstance } from 'web-workers/AppWorkerFactory';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = logger.getLogger('AnalyticsUtil');

const device = getDevice();
const AppWorkerProxy = AppWorkerFactoryInstance.create(device);

class AnalyticsUtil {
  private _previousEvent: string | null = null;
  private _mcid = '';

  constructor() {
    if (window?.s?.visitor) {
      this._mcid = getMarketingCloudVisitorID();
    }
  }

  private _cacheMcid() {
    if (this._mcid === '' && window?.s?.visitor) {
      this._mcid = getMarketingCloudVisitorID();
    }
  }

  /**
   * Manually track analytic events while setting customPropertyStore
   * without relying on React lifecycle to prevent losing events between renders
   */
  trackEvent(customPropertyInfo: CustomPropertyType, sessionName?: SessionName) {
    if (customPropertyInfo.eventName !== '') {
      const { eventName, ...customAttributes } = customPropertyInfo;

      if (typeof customPropertyInfo.videoAttribution === 'string') {
        updateVideoSession(
          { videoProperties: { videoAttribution: customPropertyInfo.videoAttribution } },
          true,
          sessionName,
        );
      }

      this._cacheMcid();

      const userProperties = userPropertyStore();
      AppWorkerProxy.analyticsTrack({
        anonymousId: getAnonymousId(),
        userId: userProperties.bcgId ?? undefined,
        event: eventName,
        integrations: { 'Adobe Analytics': { MarketingCloudVisitorID: this._mcid } },
        context: {
          traits: {
            ...persistentPropertyStore(),
            ...userProperties,
            externalCampaign: externalCampaignStore(),
          },
        },
        properties: { ...customAttributes, previousEvent: this._previousEvent },
      });

      externalCampaignStore('');
      customPropertyStore(customPropertyInfo);
      this._previousEvent = eventName;
    }
  }

  page(params: { name: string; properties: Record<string, unknown> }) {
    const userPropertyState = userPropertyStore();

    this._cacheMcid();

    AppWorkerProxy.analyticsPage({
      anonymousId: getAnonymousId(),
      userId: userPropertyState.bcgId ?? undefined,
      name: params.name,
      integrations: {
        'Adobe Analytics': {
          marketingCloudVisitorId: this._mcid,
        },
      },
      properties: {
        ...basePropertyStore(),
        referrer: pageInfoState().referrer,
        timestamp: Date.now().toString(),
        ...params.properties,
      },
      context: {
        traits: {
          ...persistentPropertyStore(),
          ...userPropertyState,
        },
      },
    });
  }
}

const analyticsUtil = new AnalyticsUtil();

export { analyticsUtil as AnalyticsUtil };
