class VideoStoreSingleton {
  private static _instance: VideoStoreSingleton;
  private static _refreshCue: number;

  private constructor() {
    // nothing here ... just closed off
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public get refreshCue(): number {
    return VideoStoreSingleton._refreshCue;
  }

  public set refreshCue(value: number) {
    if (value != null) {
      VideoStoreSingleton._refreshCue = value;
    }
  }
}

export const VIDEO = VideoStoreSingleton.Instance;
