import { SessionName, updateVideoSession } from 'state-machines/video-session-state';

export enum LivePlayerStateNames {
  MINI = 'MINI',
  FULLSCREEN = 'FULLSCREEN',
  INACTIVE = 'INACTIVE',
}

export type LivePlayerState = {
  state: LivePlayerStateNames;
  currentMediaId: string;
};

export const LivePlayerStates: { [state in LivePlayerStateNames]: LivePlayerState } = {
  MINI: {
    state: LivePlayerStateNames.MINI,
    currentMediaId: '',
  },
  FULLSCREEN: {
    state: LivePlayerStateNames.FULLSCREEN,
    currentMediaId: '',
  },
  INACTIVE: {
    state: LivePlayerStateNames.INACTIVE,
    currentMediaId: '',
  },
};

export const DEFAULT_LIVE_PLAYER_STATE: LivePlayerState = {
  state: LivePlayerStateNames.INACTIVE,
  currentMediaId: '',
};

export const useUpdateLiveMediaId = (mediaId: string, sessionName?: SessionName): void => {
  updateVideoSession({ livePlayerState: { currentMediaId: mediaId } }, true, sessionName);
};

export type ContinuousPlay = {
  bracketID: number;
  startTimeEpoch: number | void;
};
