import { logger } from '../../logger';
import { webOSApi } from './webOSProdApi';
import { default as monitor } from 'utils/monitor';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = logger.getLogger('webOSApi');

export type WebOSError = { errorCode?: string; errorText: string };

export interface WebOSApi {
  getDeviceId(): Promise<string>;
  getDeviceInfo(): Promise<DeviceInfo>;
  getSystemCaptionsEnabled(): Promise<boolean | null>;
  launchApp(parameters: { id: string; params?: { query: string } }): Promise<boolean | null>;
}

export function getWebOSApi(): WebOSApi | undefined {
  if (self.webOS) {
    return webOSApi;
  }

  const errorMessage = 'Requested WebOSApi before it was defined';
  LOG.error(errorMessage);
  monitor.sendError(errorMessage);

  return undefined;
}
