import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { DEFAULT_COLOR, DEFAULT_TEAM_NAME } from 'defaults/default-data-types';
import {
  BoxscoreDetails,
  ChampionshipTeam,
  Location,
  RegionSection,
  Round,
  TeamLogoConfig,
  TeamsOnDeck,
} from 'types/generated-types';
import { convertTrademark } from 'utils/game';

export function filterByFieldValue<T, K extends keyof T>(items: T[], field: K, value: T[K] | unknown): T | null {
  const found = items.find((item: T) => item[field] === value);
  return found ?? null;
}

export function getTeamsOnDeck(teams: number): TeamsOnDeck {
  switch (teams) {
    case 0:
      return TeamsOnDeck.None;
    case 1:
      return TeamsOnDeck.One;
    case 2:
      return TeamsOnDeck.Two;
  }
  return TeamsOnDeck.None;
}

// Not very 'pure' ... please refactor
export function pluckReadFieldValue<T>(
  read: ReadFieldFunction,
  fieldName: string,
  targetField: keyof T,
  value: unknown,
): T | null {
  const found = (read(fieldName) as T[]) ?? null;

  if (found != null && found.length > 0) {
    return filterByFieldValue(found, targetField, value);
  }
  return null;
}

export function teamLogoFactory(team: ChampionshipTeam | BoxscoreDetails): TeamLogoConfig {
  return {
    color: (team.color as string) ?? DEFAULT_COLOR,
    logoUri: (team?.seoname as unknown as string) ?? '',
    name: (team?.nameShort as unknown as string) ?? DEFAULT_TEAM_NAME,
  };
}

export function createVenueTitle(readField: ReadFieldFunction): string {
  const locationRef = readField<Location>('location');
  if (locationRef != null) {
    const venue = readField('venue', locationRef);
    const city = readField('city', locationRef);
    const state = readField('stateUsps', locationRef);
    return `${[venue, [city, state].filter(Boolean).join(', ')].filter(Boolean).join(' - ')}`;
  }
  return '';
}

export function createformattedRoundTitle(readField: ReadFieldFunction): string {
  const regionSectionRef = readField<RegionSection>('region');
  const roundRef = readField<Round>('round');
  const roundTitle = readField('title', roundRef) ?? '';
  const regionTitle = `${readField('title', regionSectionRef)}`;
  return `${[convertTrademark(roundTitle as string).toLowerCase(), regionTitle].filter(Boolean).join(' - ')}`;
}
