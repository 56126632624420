export enum StatsSectionStateNames {
  INACTIVE = 'INACTIVE',
  UPCOMING = 'UPCOMING',
  PRE_GAME = 'PRE_GAME',
  LIVE = 'LIVE',
  LIVE_BLACKOUT = 'LIVE_BLACKOUT',
  FINAL = 'FINAL',
  UNPLAYED_FINAL = 'UNPLAYED_FINAL',
}

export type StatsSectionState = {
  id: string;
  canShow: boolean;
  title: string;
  data: string;
};

export const StatSectionStates: { [state in StatsSectionStateNames]: StatsSectionState } = {
  INACTIVE: {
    id: StatsSectionStateNames.INACTIVE,
    canShow: false,
    title: '',
    data: '',
  },
  UPCOMING: {
    id: StatsSectionStateNames.UPCOMING,
    canShow: true,
    title: 'SEASON AVERAGES',
    data: 'season averages',
  },
  PRE_GAME: {
    id: StatsSectionStateNames.PRE_GAME,
    canShow: true,
    title: 'SEASON AVERAGES',
    data: 'season averages',
  },
  LIVE: {
    id: StatsSectionStateNames.LIVE,
    canShow: true,
    title: 'LIVE STATS',
    data: 'live stats',
  },
  LIVE_BLACKOUT: {
    id: StatsSectionStateNames.LIVE_BLACKOUT,
    canShow: false,
    title: '',
    data: '',
  },
  FINAL: {
    id: StatsSectionStateNames.FINAL,
    canShow: true,
    title: 'GAME STATS',
    data: 'game stats',
  },
  UNPLAYED_FINAL: {
    id: StatsSectionStateNames.UNPLAYED_FINAL,
    canShow: true,
    title: 'SEASON AVERAGES',
    data: 'season averages',
  },
};
