// const seeking

export type VideoControlsState = {
  isLive?: boolean;
  modalOpen: boolean;
  seekWarm?: boolean;
};

export const VIDEO_CONTROLS_INITIAL_STATE: VideoControlsState = {
  isLive: false,
  modalOpen: false,
  seekWarm: false,
};

// const seeking

export type VideoScrubberState = {
  seekHot: boolean;
};

export const VIDEO_SCRUBBER_STATE_INITIAL: VideoScrubberState = {
  seekHot: false,
};
