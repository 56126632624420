import { useEffect } from 'react';

const cache: Record<string, number> = {};

export default function useInstanceCounter(key: string) {
  useEffect(() => {
    cache[key] = (cache[key] ?? 0) + 1;

    return () => {
      cache[key] -= 1;
    };
  }, [key]);

  return cache[key];
}
