import Link from '@mui/material/Link';
import { UseFocusableConfig } from '@noriginmedia/norigin-spatial-navigation';
import useFocusItem from 'hooks/useFocusItem';
import { FC, ReactNode } from 'react';
import classNames from 'utils/classNames';

interface AppUpdateDialogButtonProps extends UseFocusableConfig {
  children: ReactNode;
  onClick: () => void;
}

const AppUpdateDialogButton: FC<AppUpdateDialogButtonProps> = ({ children, onClick, ...props }) => {
  const { ref, focused } = useFocusItem({
    ...props,
    onEnterPress: (extraProps, details) => {
      onClick();
      props.onEnterPress?.(extraProps, details);
    },
  });

  return (
    <Link
      ref={ref}
      component="button"
      color="inherit"
      onClick={onClick}
      className={classNames({ 'nav-selected': focused })}
    >
      <span className="ConnButtonPrimaryLVL-5">{children}</span>
    </Link>
  );
};

export default AppUpdateDialogButton;
