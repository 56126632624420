import React, { FC, PropsWithChildren, useMemo } from 'react';
import { from } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { createPersistedQueryLink, ErrorResponse } from '@apollo/client/link/persisted-queries';
import { logger } from '../logger/logger';
import { ApolloClient, ApolloProvider, DocumentNode, HttpLink, InMemoryCache } from '@apollo/client';
import { fetchLink } from './fetch-link';
import { shouldUseMockServiceWorker } from 'utils/assets';
import { createFetchHack } from 'utils/webos/fetchHack';
import { usePregeneratedHashes as pregeneratedHashes } from 'graphql-codegen-persisted-query-ids/lib/apollo';
import hashes from './client.json';

const LOG = logger.getLogger('InitializeApolloPersisted');

// dev static uat qa uat mml-test staging prod
export enum RwEndpointKeys {
  static = 'static',
  qa = 'qa',
  uat = 'uat',
  mmltest = 'mml-test',
  staging = 'staging',
  prod = 'prod',
}

interface InitializeApolloProps {
  uri: string;
  appVersion: string;
  appName: string;
  cache: InMemoryCache;
  typeDefs: string | string[] | DocumentNode | DocumentNode[];
  readWriteKey?: RwEndpointKeys;
}

export const InitializeApolloPersisted: FC<PropsWithChildren<InitializeApolloProps>> = (props) => {
  let uri = props.uri;

  if (props.readWriteKey != null) {
    //   url = "https://sdata-ncaa-gql.#{envName}.sdata-cloud.com/?"
    uri = `https://sdata-ncaa-gql.${props.readWriteKey.toString()}.sdata-cloud.com/`;
  }

  const apolloLink = useMemo(() => {
    // Log to console query name and relevant SHA
    const gqlError = (error: ErrorResponse) => {
      LOG.info('========= GRAPHQL PERSISTED QUERIES =================');
      LOG.info('## ERROR RESPONSE: ', error.graphQLErrors);
      LOG.info('## GQL Endpoint:', uri);
      LOG.info(`## NAME:${error.operation.operationName}`);
      LOG.info(`## SHA:${error.operation.extensions.persistedQuery?.sha256Hash}`);
      return true;
    };

    // Setup httpLink, pass in sha strategy
    const httpLink = new HttpLink({
      uri: uri,
      fetch: createFetchHack(),
    });
    const retryLink = new RetryLink({
      delay: {
        initial: 5000,
      },
      attempts: {
        max: 5,
      },
    });

    const persistedQueriesLink = createPersistedQueryLink({
      generateHash: pregeneratedHashes(hashes),
      useGETForHashedQueries: true,
      disable: gqlError,
    });

    return shouldUseMockServiceWorker()
      ? from([fetchLink(), persistedQueriesLink, httpLink])
      : from([persistedQueriesLink, retryLink, httpLink]);
  }, [uri]);

  // setup apollo client
  const apolloClient = useMemo(() => {
    return new ApolloClient({
      cache: props.cache,
      name: props.appName,
      version: props.appVersion,
      typeDefs: props.typeDefs,
      link: apolloLink,
      // This causes infinite retries/fetching
      // defaultOptions: {
      //   watchQuery: {
      //     fetchPolicy: 'cache-and-network',
      //   },
      // },
    });
  }, [apolloLink, props.appName, props.appVersion, props.cache, props.typeDefs]);

  return <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>;
};
