import { gql } from '@apollo/client';

//  client schema

export const CLIENT_SCHEMA = gql`
  # import ChampionshipGame from './sdatastatic.graphql'
  # import Boxscore from './sdatastatic.graphql'
  # import ChampionshipTeam from './sdatastatic.graphql'
  # import StaticTestEnv from 'sdatastatic.graphql'
  # import AppFeaturedView from 'sdatastatic.graphql'
  # import TeamStatsBasketball from 'sdatastatic.graphql'
  # import BoxscoreDetails from 'sdatastatic.graphql'
  # import ChampionshipTeam from 'sdatastatic.graphql'
  # import Championship from 'sdatastatic.graphql'
  # import Round from 'sdatastatic.graphql'
  # import AppFeatured from 'sdatastatic.graphql'
  # import TeamStatsBasketball from 'sdatastatic.graphql'
  # import Location from 'sdatastatic.graphql'
  # import TimeMachineProgram from 'sdatastatic.graphql'

  enum LoggerLevel {
    ALL
    DEBUG
    INFO
    WARN
    ERROR
    FATAL
  }

  enum TeamsOnDeck {
    NONE
    ONE
    TWO
  }

  type LogMessageModel {
    message: String!
    level: LoggerLevel!
  }

  type TeamLogoConfig @client {
    color: String!
    logoUri: String!
    name: String!
  }

  # Clent-side type policies need to go here
  type ChampionshipGame @client {
    # split by isTop
    topChampionshipTeam: ChampionshipTeam
    bottomChampionshipTeam: ChampionshipTeam
    # split by isHome
    homeChampionshipTeam: ChampionshipTeam
    visitorChampionshipTeam: ChampionshipTeam
    # how many teamsOnDeck
    teamsOnDeck: TeamsOnDeck!
    # team lgoos
    homeTeamLogo: TeamLogoConfig
    visitorTeamLogo: TeamLogoConfig
    # season average
    homeSeasonAverage: TeamStatsBasketball
    visitorSeasonAverage: TeamStatsBasketball
    formattedVenue: String
    formattedClock: String
    formattedRound: String
  }

  type Boxscore {
    # split boxscores
    homeBoxscore: BoxscoreDetails
    visitorBoxscore: BoxscoreDetails
  }

  # Clent-side queries go here
  extend type Query {
    playerReady: Boolean
    getLogs: [LogMessageModel]
    appFeatured(seasonYear: Int, staticTestEnv: StaticTestEnv, view: AppFeaturedView): AppFeatured
    round(roundNumber: Int!): Round
    championship: Championship
    rounds: [Round]
    championshipGame(seasonYear: Int, staticTestEnv: StaticTestEnv, bracketId: Int): ChampionshipGame!
    mmlContests(seasonYear: Int, bracketId: Int, current: Boolean, staticTestEnv: StaticTestEnv): [ChampionshipGame]!
    timemachine(seasonYear: Int!, bracketId: Int!, staticTestEnv: StaticTestEnv): [TimeMachineProgram]!
  }
`;
