import { useEffect } from 'react';
import { documentVisibilityStore } from 'apolloGraphql/mml-apollo-cache';
import { logger } from '../logger';

const LOG = logger.getLogger('handleVisibilityChanged');

function getEventName() {
  if (typeof document.msHidden !== 'undefined') return 'msvisibilitychange';
  if (typeof document.webkitHidden !== 'undefined') return 'webkitvisibilitychange';
  return 'visibilitychange';
}

export function handleVisibilityChanged() {
  documentVisibilityStore(document.visibilityState === 'visible');
  LOG.info(`document visibility changed to ${documentVisibilityStore()}`);
}

export function useVisibilityChange() {
  const eventName = getEventName();

  useEffect(() => {
    document.addEventListener(eventName, handleVisibilityChanged);

    return () => {
      document.removeEventListener(eventName, handleVisibilityChanged);
    };
  }, [eventName]);
}
