import type { ClientlessAuthEngine } from '@top/auth-clientless-web-lite';
import { logger } from '../../../logger/logger';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = logger.getLogger('TVESingletons');

class TVESingletons {
  private static _instance: TVESingletons;
  private static _authLib: ClientlessAuthEngine;

  private constructor() {
    // nothing here ... just closed off
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public get authLib(): ClientlessAuthEngine {
    return TVESingletons._authLib;
  }

  public set authLib(value: ClientlessAuthEngine) {
    if (TVESingletons._authLib == null) {
      TVESingletons._authLib = value;
    }
  }
}

export const TVE = TVESingletons.Instance;
