export enum MarqueeStateNames {
  UPCOMING = 'UPCOMING',
  PRE_GAME = 'PRE_GAME',
  LIVE = 'LIVE',
  FINAL = 'FINAL',
  LIVE_UNAUTHORIZED = 'LIVE_UNAUTHORIZED',
  LIVE_BLACKOUT = 'LIVE_BLACKOUT',
  FINAL_UNAUTHORIZE = 'FINAL_UNAUTHORIZED',
}
export type MarqueeState = {
  id: string;
  showMarquee: boolean;
  showVideo: boolean;
};

export const MarqueeStates: { [state in MarqueeStateNames]: MarqueeState } = {
  UPCOMING: {
    id: MarqueeStateNames.UPCOMING,
    showMarquee: true,
    showVideo: false,
  },
  PRE_GAME: {
    id: MarqueeStateNames.PRE_GAME,
    showMarquee: false,
    showVideo: true,
  },
  LIVE: {
    id: MarqueeStateNames.LIVE,
    showMarquee: false,
    showVideo: true,
  },
  LIVE_UNAUTHORIZED: {
    id: MarqueeStateNames.LIVE_UNAUTHORIZED,
    showMarquee: true,
    showVideo: false,
  },

  LIVE_BLACKOUT: {
    id: MarqueeStateNames.LIVE_BLACKOUT,
    showMarquee: true,
    showVideo: false,
  },

  FINAL: {
    id: MarqueeStateNames.FINAL,
    showMarquee: true,
    showVideo: false,
  },
  FINAL_UNAUTHORIZED: {
    id: MarqueeStateNames.FINAL_UNAUTHORIZE,
    showMarquee: true,
    showVideo: false,
  },
};
