import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

export const Button = styled(DialogActions)({
  justifyContent: 'space-around',
  width: 'calc(100% - ((100% - 170px - 170px) / 3))',
  margin: '0 auto',
  alignItems: 'center',
  display: 'flex',
  height: 77,
  '& button': {
    border: 'solid 1px var(--color_lvl_2)',
    borderRadius: '4px',
    padding: '21px 0',
    width: 170,
    '& span': {
      textTransform: 'uppercase',
    },
    '&.active, &:hover, &:focus, &.nav-selected': {
      backgroundColor: 'var(--color_lvl_3)',
      color: 'var(--color_lvl_-5)',
      padding: '21px 0',
      textDecoration: 'none',
      transform: 'scale(1.1)',
      '& span': {
        color: 'var(--color_lvl_-5)',
      },
    },
  },
});

export const DialogContainer = styled(Dialog)({
  '.MuiDialog-paper': {
    backgroundColor: 'var(--color_lvl_-5)',
    width: '93%',
    fontFamily: 'RingsideWide-Semi',
    textTransform: 'capitalize',
    padding: 20,
    fontSize: '20px',
  },
});

export const RootTitle = styled(DialogTitle)({
  color: 'var(--color_lvl_5)',
  fontSize: '25px',
});
