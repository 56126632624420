import { Kochava } from 'kochava';
import { logger } from 'logger';
import { isProdDomain } from './assets';
import { Device, getDevice } from './getDevice';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = logger.getLogger('kochava');

type KochavaDeviceGuids = {
  release: string;
  debug: string;
};

let kochava: Kochava;

const kochavaGUIDs: { [device in Device]: KochavaDeviceGuids } = {
  firetv: {
    release: process.env.KOCHAVA_APP_GUID_RELEASE_FIRE_TV as string,
    debug: process.env.KOCHAVA_APP_GUID_DEBUG_FIRE_TV as string,
  },
  androidtv: {
    release: process.env.KOCHAVA_APP_GUID_RELEASE_ANDROID_TV as string,
    debug: process.env.KOCHAVA_APP_GUID_DEBUG_ANDROID_TV as string,
  },
  lgtv: {
    release: process.env.KOCHAVA_APP_GUID_RELEASE_LGTV as string,
    debug: process.env.KOCHAVA_APP_GUID_DEBUG_LGTV as string,
  },
  xbox: {
    release: process.env.KOCHAVA_APP_GUID_RELEASE_XBOX as string,
    debug: process.env.KOCHAVA_APP_GUID_DEBUG_XBOX as string,
  },
};

export const initKochava = () => {
  LOG.info('Initializing Kochava.');
  kochava = Kochava.createForReact();
  kochava.disableAutoPage(true);
  kochava.setLogLevel('warn');
  kochava.startWithAppGuid(getKochavaGUID());
};

export const getKochavaGUID = (): string => {
  const environment = isProdDomain() ? 'release' : 'debug';
  const kochavaGUID = kochavaGUIDs[getDevice()][environment];
  return kochavaGUID;
};

export const getKochava = (): Kochava => {
  if (!kochava) {
    initKochava();
  }
  return kochava;
};
