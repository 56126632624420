/// <reference lib="ESNEXT" />
/// <reference lib="webworker" />

import '@ungap/global-this';
import { Analytics, IdentifyParams, PageParams, TrackParams } from '@segment/analytics-node';
import { expose } from 'comlink';
import { logger } from 'logger';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = logger.getLogger('AnalyticsWorkerSingleton');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const self: DedicatedWorkerGlobalScope;

class AnalyticsWorkerSingleton {
  private _analytics?: Analytics;
  private static _instance: AnalyticsWorkerSingleton;

  private async waitForReady() {
    if (!this._analytics) throw new Error('Analytics has not yet been loaded.');
    await this._analytics.ready;
  }

  public static get Instance() {
    return this._instance ?? (this._instance = new this());
  }

  public async load(writeKey = 'test') {
    this._analytics = new Analytics({ writeKey, maxEventsInBatch: 1 });
    this._analytics.on('error', (err) => {
      LOG.debug(`Segment analytics failed to load ${err}`);
    });

    return await this.waitForReady();
  }

  public async page(pageParams: PageParams) {
    await this.waitForReady();

    return new Promise<void>((resolve, reject) => {
      this._analytics?.page(pageParams, (error) => {
        if (error) return reject(error);
        return resolve();
      });
    });
  }

  public async identify(identifyParams: IdentifyParams) {
    await this.waitForReady();

    return new Promise<void>((resolve, reject) => {
      this._analytics?.identify(identifyParams, (error) => {
        if (error) return reject(error);
        return resolve();
      });
    });
  }

  public async track(trackParams: TrackParams) {
    await this.waitForReady();

    return new Promise<void>((resolve, reject) => {
      this._analytics?.track(trackParams, (error) => {
        if (error) return reject(error);
        return resolve();
      });
    });
  }
}

export const AnalyticsWorker = AnalyticsWorkerSingleton.Instance;

export type IAnalyticsWorker = typeof AnalyticsWorker;

expose(AnalyticsWorker);
