import { FC, useEffect, useState } from 'react';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import { ModalProps } from '@mui/material/Modal';
import { useAppContext } from 'context/app-context';
import { APP_VERSION } from 'version';
import { useReactiveVar } from '@apollo/client';
import { ignoredUpdateVersion, showAppUpdateDialogStore } from 'apolloGraphql/mml-apollo-cache';
import { shouldUseMockServiceWorker } from 'utils/assets';
import * as serviceWorker from '../../serviceWorkerRegistration';
import AppUpdateDialogButton from './AppUpdateDialogButton';
import { FocusKeys } from 'components/SpacialNavigation/focusKeys';
import { Button, DialogContainer, RootTitle } from './styles';
import { FocusContext } from '@noriginmedia/norigin-spatial-navigation';
import useFocusItem from 'hooks/useFocusItem';
import semverLessThan from 'semver/functions/lt';
import semverCoerce from 'semver/functions/coerce';
import semverValid from 'semver/functions/valid';
import classNames from 'utils/classNames';

interface FuncProps {
  onUpdateCheck: (values: boolean) => void;
}

//FIXME:  remove this from the hierarchy until it's needed.  Or conversely, fix the useFocusItem() hook so this
// doesn't take so long when we run it (useFocusItem is the real culprit here, just not sure how much we'll be able
// to slim it down).  The dialog related styling that also runs along side this appears to take quite a bit of time
// as well though.
const AppUpdateDialogue: FC<FuncProps> = ({ onUpdateCheck }) => {
  const [showCancel, setCancelButton] = useState(true);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);
  const { appConfig } = useAppContext();
  const ignoredVersion = useReactiveVar(ignoredUpdateVersion);
  const showAppUpdateDialog = useReactiveVar(showAppUpdateDialogStore);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    showAppUpdateDialogStore(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    if (appConfig) {
      if (compareVersions(APP_VERSION.version, appConfig.app.mandatory_version)) {
        ignoredUpdateVersion('');
        setCancelButton(false);
        showAppUpdateDialogStore(true);
        onUpdateCheck(true);
      } else {
        if (
          compareVersions(APP_VERSION.version, appConfig.app.latest_version) &&
          (!ignoredVersion || compareVersions(ignoredVersion, appConfig.app.latest_version))
        ) {
          setCancelButton(true);
          showAppUpdateDialogStore(true);
        }
        onUpdateCheck(false);
      }
    }
  }, [appConfig?.app.mandatory_version, appConfig, ignoredVersion, showAppUpdateDialog]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!shouldUseMockServiceWorker()) {
      serviceWorker.register({ onUpdate: onSWUpdate });
    }
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    showAppUpdateDialogStore(false);
    self.location.reload();
  };

  const onCancel = () => {
    showAppUpdateDialogStore(false);
    ignoredUpdateVersion(appConfig?.app.latest_version);
  };

  const handleOnClose: ModalProps['onClose'] = (event, reason) => {
    if (reason === 'backdropClick') return;
  };

  const { ref, focusKey, focusSelf, focused } = useFocusItem({
    focusKey: FocusKeys.AppUpdateDialog,
    focusable: showAppUpdateDialog,
    isFocusBoundary: showAppUpdateDialog,
    disableScrollBehaviour: true,
  });

  useEffect(() => {
    if (showAppUpdateDialog) {
      focusSelf();
    }
  }, [focusSelf, showAppUpdateDialog]);

  return (
    <DialogContainer
      onClose={handleOnClose}
      open={showAppUpdateDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <FocusContext.Provider value={focusKey}>
        <RootTitle id="alert-dialog-title">{APP_UPDATE_TITLE}</RootTitle>
        <DialogContent>
          <DialogContentText style={{ color: 'var(--color_lvl_5)', fontSize: 20 }} id="alert-dialog-description">
            {APP_UPDATE_MESSAGE}
          </DialogContentText>
        </DialogContent>
        <Button ref={ref} className={classNames({ 'nav-selected': focused })}>
          <AppUpdateDialogButton onClick={reloadPage}>{APP_UPDATE_POSITIVE_BUTTON_LABEL}</AppUpdateDialogButton>
          {showCancel && <AppUpdateDialogButton onClick={onCancel}>Not Now</AppUpdateDialogButton>}
        </Button>
      </FocusContext.Provider>
    </DialogContainer>
  );
};

export const APP_UPDATE_TITLE = 'Update Available';
export const APP_UPDATE_MESSAGE = 'A new version of NCAA March Madness Live is available.';
export const APP_UPDATE_POSITIVE_BUTTON_LABEL = 'Update';

export default AppUpdateDialogue;

/**
 * Returns whether v1 is less than v2, coercing both strings
 * to a valid semver if possible
 */
const compareVersions = (v1: string, v2: string) => {
  const semver1 = semverValid(semverCoerce(v1));
  const semver2 = semverValid(semverCoerce(v2));
  if (semver1 && semver2) {
    return semverLessThan(semver1, semver2);
  }
  return v1 < v2;
};
