import { useEffect } from 'react';
import { Device, getDevice } from '../utils/getDevice';
import monitor from '../utils/monitor';
import { APP_VERSION } from 'version';
import { useAppContext } from '../context/app-context';

const device = getDevice();
export const useNewRelicData = () => {
  const { webosDeviceInfo } = useAppContext();

  useEffect(() => {
    monitor.setReleaseVersion(APP_VERSION.version);
  }, []);

  useEffect(() => {
    if (device === Device.lgtv && webosDeviceInfo) {
      monitor.addPersistentAttributes('webosVersion', webosDeviceInfo.sdkVersion);
      monitor.addPersistentAttributes('modelName', webosDeviceInfo.modelName);
    }
  }, [webosDeviceInfo]);

  useEffect(() => {
    if (device === Device.androidtv || device === Device.firetv) {
      if (window && self.Android && monitor) {
        monitor.addPersistentAttributes('modelName', self.Android.getModelName());
        monitor.addPersistentAttributes('apiLevel', self.Android.getApiLevel());
      }
    }
  }, []);

  useEffect(() => {
    if (device === Device.xbox) {
      if (window?.UWP) {
        monitor.addPersistentAttributes('modelName', self.UWP.model);
        monitor.addPersistentAttributes('modelVersion', self.UWP.modelVersion);
      }
    }
  }, []);
};
