/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-var */
import { useEffect, useState } from 'react';
import { debugEnabled } from 'utils/dom-utils';
import { isProdDomain } from 'utils/assets';
import { logger } from 'logger';
import { useReactiveVar } from '@apollo/client';
import { appReady } from 'apolloGraphql/mml-apollo-cache';
import { LogWrapper } from 'video-api';

const DEBUG_QUERY_STRINGS = ['debug', 'debugConfig', 'topdebug', 'fw'];

interface Props {
  disableLogs: (disable: boolean) => void;
}
export const useKillLogs = (): Props => {
  const [disable, setDisable] = useState<boolean>(false);
  const { initialQueriesComplete, minTimeSponsorShown } = useReactiveVar(appReady);

  useEffect(() => {
    const isProd = isProdDomain();

    const enableLogs = debugEnabled(DEBUG_QUERY_STRINGS);

    if (isProd && !enableLogs) {
      if (initialQueriesComplete && minTimeSponsorShown) {
        const logger = new LogWrapper();
        logger.setEnabled(false);
      }
      logger.disableAll();
      console.log('\n\n :: CONSOLE unavailable w/o param :: \n\n');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!(window as any).console) (window as any).console = {};
      var methods = ['log', 'debug', 'warn', 'info'];
      for (let i = 0; i < methods.length; i++) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (console as any)[methods[i]] = function () {};
      }
    }
  }, [disable, initialQueriesComplete, minTimeSponsorShown]);

  return { disableLogs: (disable: boolean) => setDisable(disable) };
};

export const isLocalhost = Boolean(
  self.location.hostname === 'localhost' ||
    self.location.hostname === 'local.ncaa.com' ||
    // [::1] is the IPv6 localhost address.
    self.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    self.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);
