import { lazy } from '@loadable/component';
import { FC } from 'react';
import VideoLoadingAnimation from './VideoLoadingAnimation';

const LottieLoadingAnimation = lazy(
  () => import(/* webpackChunkName: "LottieLoadingAnimation" */ './LottieLoadingAnimation'),
);

export enum AnimationType {
  Lottie,
  Video,
}

export type LoadingAnimationProps = {
  animationType: AnimationType;
  onComplete: () => void;
};

const LoadingAnimation: FC<LoadingAnimationProps> = (props) => {
  switch (props.animationType) {
    case AnimationType.Lottie:
      return <LottieLoadingAnimation onComplete={props.onComplete} />;

    case AnimationType.Video:
      return <VideoLoadingAnimation onComplete={props.onComplete} />;
  }
};

export default LoadingAnimation;
