import { createGlobalStyle } from 'styled-components';

// Allows scrollwheel to scroll screen on webOS
const WebosGlobalStyle = createGlobalStyle`
  #root {
    overflow-y: auto;
    height: 100%;
  }

  #root::-webkit-scrollbar {
    display: none;
  }

  html,
  body {
    height: 100%;
  }
`;

export default WebosGlobalStyle;
