/**
 * Since webOS 3 and 4 use chromium 38 and 53 respectively, they don't support the wildcard (*)
 * in the Access-Control-Allow-Headers returned by our graphql server as this wasn't introduced
 * until [chrome/chromium 62](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Access-Control-Allow-Headers#browser_compatibility)
 * (these versions think the wild card is literal, and will therefore only match a literal "*").
 *
 * One possible workaround for this would be to have the server/backend team echo the Access-Control-Allow-Headers
 * we send to them back to us, but we're close enough to the tournament ship date that I'd rather not go
 * through that process (and find out maybe there are security reasons, or risk screwing up other platforms, or
 * find they can't make the change faster than I can merge this hack in to get webOS working for people).
 */
import { Device, getDevice } from '../getDevice';

export function createFetchHack(): typeof fetch {
  return (input: RequestInfo | URL, init?: RequestInit | undefined): Promise<Response> => {
    if (getDevice() === Device.lgtv && init?.headers) {
      let headers = init?.headers;
      if (!(headers instanceof Headers)) {
        headers = new Headers(headers);
      }
      headers.delete('content-type');
      headers.delete('apollographql-client-name');
      headers.delete('apollographql-client-version');
      init.headers = headers;
    }

    return fetch(input, init);
  };
}
