import { HttpLink } from '@apollo/client';

/**
 * Create explicit `self.fetch` so that outgoing requests
 * are captured and deferred until our service worker is ready.
 */
export const fetchLink = () => {
  return new HttpLink({
    fetch: (...args: [RequestInfo]) => fetch(...args),
  });
};
