"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerFriendlyObstructionPurpose = exports.SubjectToGDPROptions = exports.TCFVersion = exports.LifecycleState = exports.ContentProtectionType = exports.CmsProviderKey = exports.MediumVersion = exports.VideoEngineMetadataType = exports.PlayModeOptions = exports.AutoPlayPreloadBehavior = exports.ComponentCategory = exports.DRMDataSource = exports.AuthDrmTokenType = exports.AuthCdnTokenType = exports.ErrorSeverity = exports.CategoryCode = exports.DeviceType = exports.PlayerState = exports.KeySystems = exports.AuthTokenType = exports.MediaState = exports.MediaJSONPlatform = exports.AdState = exports.AdBreakPosition = void 0;
/**
 * Describes where _(temporally)_ in `media` the `adBreak` will occur
 *
 * @see [[ISharedPlayer.getCurrentAdBreak]
 * @see [[AdBreakMetadata.position]]
 */
var AdBreakPosition;
(function (AdBreakPosition) {
    AdBreakPosition["Unknown"] = "unknown";
    /** Happens at the beginning of content, before media starts */
    AdBreakPosition["Preroll"] = "preroll";
    /** Happens in the middle of content, while media is ongoing */
    AdBreakPosition["Midroll"] = "midroll";
    /** Happens at the end of content, once media has finished */
    AdBreakPosition["Postroll"] = "postroll";
})(AdBreakPosition = exports.AdBreakPosition || (exports.AdBreakPosition = {}));
var AdState;
(function (AdState) {
    AdState["Pending"] = "pending";
    AdState["Starting"] = "starting";
    AdState["Loaded"] = "loaded";
    AdState["Playing"] = "playing";
    AdState["Paused"] = "paused";
    AdState["Buffering"] = "buffering";
    AdState["Stopped"] = "stopped";
    AdState["Finished"] = "finished";
    AdState["Error"] = "error";
})(AdState = exports.AdState || (exports.AdState = {}));
var MediaJSONPlatform;
(function (MediaJSONPlatform) {
    MediaJSONPlatform["TV"] = "tv";
    MediaJSONPlatform["Desktop"] = "desktop";
    MediaJSONPlatform["Tablet"] = "tablet";
    MediaJSONPlatform["Phone"] = "phone";
})(MediaJSONPlatform = exports.MediaJSONPlatform || (exports.MediaJSONPlatform = {}));
/**
 * States of media playback
 */
var MediaState;
(function (MediaState) {
    MediaState["Pending"] = "pending";
    MediaState["Starting"] = "starting";
    MediaState["Loaded"] = "loaded";
    MediaState["Playing"] = "playing";
    MediaState["Paused"] = "paused";
    MediaState["Buffering"] = "buffering";
    MediaState["Seeking"] = "seeking";
    MediaState["Stopped"] = "stopped";
    MediaState["Finished"] = "finished";
    MediaState["Error"] = "error";
})(MediaState = exports.MediaState || (exports.MediaState = {}));
var AuthTokenType;
(function (AuthTokenType) {
    AuthTokenType["Adobe"] = "adobe";
    AuthTokenType["JWS"] = "jws";
})(AuthTokenType = exports.AuthTokenType || (exports.AuthTokenType = {}));
var KeySystems;
(function (KeySystems) {
    KeySystems["Widevine"] = "widevine";
    KeySystems["Fairplay"] = "fairplay";
    KeySystems["Playready"] = "playready";
})(KeySystems = exports.KeySystems || (exports.KeySystems = {}));
var PlayerState;
(function (PlayerState) {
    PlayerState["Pending"] = "playerPending";
    PlayerState["Initializing"] = "playerInitializing";
    PlayerState["Initialized"] = "playerInitialized";
    PlayerState["Attaching"] = "playerAttaching";
    PlayerState["Attached"] = "playerAttached";
    PlayerState["Listening"] = "playerListening";
    PlayerState["Detaching"] = "playerDetaching";
    PlayerState["Detached"] = "playerDetached";
    PlayerState["Destroying"] = "playerDestroying";
    PlayerState["Destroyed"] = "playerDestroyed";
    PlayerState["Error"] = "playerError";
})(PlayerState = exports.PlayerState || (exports.PlayerState = {}));
/**
 * The category of device
 */
var DeviceType;
(function (DeviceType) {
    DeviceType["Android_Tv"] = "android_tv";
    DeviceType["Fire_Tv"] = "fire_tv";
    DeviceType["Tv_Os"] = "tv_os";
    DeviceType["Phone"] = "phone";
    DeviceType["Tablet"] = "tablet";
    DeviceType["Desktop"] = "desktop";
    DeviceType["Console"] = "console";
    DeviceType["Unknown"] = "unknown";
})(DeviceType = exports.DeviceType || (exports.DeviceType = {}));
var CategoryCode;
(function (CategoryCode) {
    CategoryCode["Unspecified"] = "___";
    /** 000 - 099 are for generic error that apply across categories */
    CategoryCode["NetworkRequestFailure"] = "000";
    CategoryCode["NetworkConnectionLost"] = "001";
    /** 100 - 199 are for the Player category */
    CategoryCode["PlayerInitFailure"] = "100";
    /** 200 - 299 are for the View category */
    CategoryCode["ViewAttachFailure"] = "200";
    CategoryCode["ViewDetachFailure"] = "201";
    /** 300 - 399 are for the Transaction category */
    CategoryCode["TransactionInitFailure"] = "300";
    CategoryCode["TransactionStateTransitionFailure"] = "301";
    CategoryCode["TransactionComponentSetupFailure"] = "302";
    /** 400 - 499 are for the C-Config category */
    /** 500 - 599 are for the C-CMS category */
    CategoryCode["ContentResolutionFailure"] = "500";
    CategoryCode["ContentSelectionFailure"] = "501";
    CategoryCode["ContentSupportFailure"] = "502";
    /** 600 - 699 are for the C-Auth category */
    CategoryCode["CDNTokenFailure"] = "600";
    CategoryCode["DRMTokenFailure"] = "601";
    CategoryCode["DRMAssetMetadataFailure"] = "602";
    CategoryCode["TokenMismatch"] = "603";
    CategoryCode["MaxAllowedStreamsExceeded"] = "604";
    CategoryCode["MaxAllowedRenewalsExceeded"] = "605";
    /** 700 - 799 are for the C-Timeline category */
    /** 800 - 899 are for the C-Ads category */
    CategoryCode["AdPlaybackFailure"] = "800";
    CategoryCode["AdSessionTimeout"] = "801";
    CategoryCode["AdStall"] = "802";
    /** 900 - 999 are for the C-Media category */
    CategoryCode["MediaPlaybackFailure"] = "900";
    CategoryCode["MediaContentFailure"] = "901";
    CategoryCode["MediaNetworkFailure"] = "902";
    CategoryCode["MediaDRMFailure"] = "903";
    /** 1000 - 1099 are for the C-Captions category */
    CategoryCode["CaptionsStorageFailure"] = "1000";
    /** 1100 - 1199 are for the C-Analytics category */
    CategoryCode["AnalyticsDisabled"] = "1100";
    CategoryCode["AnalyticsConfigFailure"] = "1101";
    CategoryCode["AnalyticsOpenMeasurementSettingFailure"] = "1102";
})(CategoryCode = exports.CategoryCode || (exports.CategoryCode = {}));
var ErrorSeverity;
(function (ErrorSeverity) {
    /**
     * This error is potentially recoverable by retrying.
     * For example, if the network is down, the client may attempt to
     * fetch the data again once the network is available.
     */
    ErrorSeverity["Error"] = "error";
    /**
     * This error is purely informative. The Player lifecycle can likely continue,
     * although with potential caveats or risks.
     */
    ErrorSeverity["Warn"] = "warn";
    /**
     * Errors of this nature always represent irrecoverable errors for the current playback session.
     * For any playback to a occur a new Play call would need to be made.
     */
    ErrorSeverity["Fatal"] = "fatal";
})(ErrorSeverity = exports.ErrorSeverity || (exports.ErrorSeverity = {}));
/**
 * @see [[PlayerConfigAuthService.type]]
 */
var AuthCdnTokenType;
(function (AuthCdnTokenType) {
    AuthCdnTokenType["Spe"] = "spe";
})(AuthCdnTokenType = exports.AuthCdnTokenType || (exports.AuthCdnTokenType = {}));
/**
 * @see [[PlayerConfigAuthDRMService.type]]
 */
var AuthDrmTokenType;
(function (AuthDrmTokenType) {
    AuthDrmTokenType["iSP"] = "isp";
})(AuthDrmTokenType = exports.AuthDrmTokenType || (exports.AuthDrmTokenType = {}));
/**
 * Defines where to Source DRM asset details from
 *
 * @see [[PlayerDRMDataSourceConfig]]
 */
var DRMDataSource;
(function (DRMDataSource) {
    /**
     * Source DRM asset details from the stream manifest
     */
    DRMDataSource["Manifest"] = "manifest";
    /**
     * Source DRM asset details from a calculated merge between the
     * asset's `medium` data and player config `metadata`.
     *
     * **Note:** Config `metadata` takes precedence.
     */
    DRMDataSource["Metadata"] = "metadata";
})(DRMDataSource = exports.DRMDataSource || (exports.DRMDataSource = {}));
var ComponentCategory;
(function (ComponentCategory) {
    ComponentCategory["Analytics"] = "analytics";
    ComponentCategory["Auth"] = "auth";
    ComponentCategory["AuthDRM"] = "authDRM";
    ComponentCategory["Captions"] = "captions";
    ComponentCategory["CMS"] = "cms";
    ComponentCategory["Cues"] = "cues";
    ComponentCategory["Lifecycle"] = "lifecycle";
    ComponentCategory["PlatformHelper"] = "platformHelper";
    ComponentCategory["UI"] = "ui";
    ComponentCategory["Video_Engine"] = "videoEngine";
    ComponentCategory["Mocks"] = "mocks";
    ComponentCategory["Custom"] = "custom";
})(ComponentCategory = exports.ComponentCategory || (exports.ComponentCategory = {}));
/**
 * Available options for [[PlayerConfigAutoPlayOptionsData.preloadBehavior]]
 */
var AutoPlayPreloadBehavior;
(function (AutoPlayPreloadBehavior) {
    /**
     * Specifies that if autoplay is deemed blocked, that we should halt initialization of the
     * content _before_ initialization has completed.
     *
     * @example Scenario: Setting up content and waiting for user interaction, without an analytics session started.
     */
    AutoPlayPreloadBehavior["None"] = "none";
    /**
     * Specifies that if autoplay is deemed blocked, that we should halt our content phases
     * _after_ initialization has completed.
     */
    AutoPlayPreloadBehavior["Full"] = "full";
})(AutoPlayPreloadBehavior = exports.AutoPlayPreloadBehavior || (exports.AutoPlayPreloadBehavior = {}));
/**
 * Available options for [[PlayerConfigPlaybackData.playMode]]
 */
var PlayModeOptions;
(function (PlayModeOptions) {
    /**
     * Allows web browser on mobile devices to stay inline and not
     * automatically go into [[Fullscreen]] mode _(iPhone tends to
     * do this by default)_
     */
    PlayModeOptions["Inline"] = "inline";
    /**
     * Let's web browser on mobile devices to automatically go
     * fullscreen mode once playback starts
     */
    PlayModeOptions["Fullscreen"] = "fullscreen";
})(PlayModeOptions = exports.PlayModeOptions || (exports.PlayModeOptions = {}));
var VideoEngineMetadataType;
(function (VideoEngineMetadataType) {
    VideoEngineMetadataType["EMSG"] = "emsg";
    VideoEngineMetadataType["ID3"] = "id3";
    VideoEngineMetadataType["CUETAG"] = "cueTag";
    VideoEngineMetadataType["DATERANGE"] = "dateRange";
    VideoEngineMetadataType["CUSTOM"] = "custom";
    VideoEngineMetadataType["SCTE"] = "scte";
    VideoEngineMetadataType["CAST"] = "cast";
    VideoEngineMetadataType["EVENT_STREAM"] = "eventStream";
})(VideoEngineMetadataType = exports.VideoEngineMetadataType || (exports.VideoEngineMetadataType = {}));
/**
 * Available versions of medium backend service _(media.json)_
 *
 * @see [[MediaJSONConfig.serviceUrl]]
 */
var MediumVersion;
(function (MediumVersion) {
    /**
     * Version of medium which does not require an `appId` to be specified
     */
    MediumVersion["V1"] = "v1";
    /**
     * Version of medium which requires an `appId` to be specified
     *
     * @see [[PlayerConfigMetadata.appId]]
     */
    MediumVersion["V2"] = "v2";
})(MediumVersion = exports.MediumVersion || (exports.MediumVersion = {}));
/**
 * Keys of the available content management providers
 */
var CmsProviderKey;
(function (CmsProviderKey) {
    /** Key of the provider for retrieving content via url */
    CmsProviderKey["URL"] = "top.cms.provider.url";
    /** Key of the provider for retrieving content via medium backend service */
    CmsProviderKey["MediaJSON"] = "top.cms.provider.mediaJson";
})(CmsProviderKey = exports.CmsProviderKey || (exports.CmsProviderKey = {}));
/** Available content protection types */
var ContentProtectionType;
(function (ContentProtectionType) {
    ContentProtectionType["Unknown"] = "unknown";
    ContentProtectionType["Preview"] = "preview";
    ContentProtectionType["PlayReady"] = "playready";
    ContentProtectionType["MultiDRM"] = "multidrm";
    ContentProtectionType["Fairplay"] = "fairplay";
    ContentProtectionType["Primetime"] = "primetime";
    ContentProtectionType["Widevine"] = "widevine";
    ContentProtectionType["BulkAES"] = "bulkaes";
    ContentProtectionType["Unprotected"] = "unprotected";
})(ContentProtectionType = exports.ContentProtectionType || (exports.ContentProtectionType = {}));
/**
 * Defines the behavior of the player for a `LifecycleEvent`
 *
 * @see [[LifecycleEvent]]
 * @see [[PlayerConfigLifecycleData]]
 * @see [[PlayerEventType.Lifecycle_State_Changed]]
 */
var LifecycleState;
(function (LifecycleState) {
    /** Player will continue playback when player changes to a mode configured to continue */
    LifecycleState["Continue"] = "continue";
    /** Player will pause playback when player changes to a mode configured to pause */
    LifecycleState["Pause"] = "pause";
    /** Player will stop playback and be destroyed when player changes to a mode configured to unload */
    LifecycleState["Unload"] = "unload";
})(LifecycleState = exports.LifecycleState || (exports.LifecycleState = {}));
/**
 * Available versions of the consent framework
 *
 * @see [[GDPRFeatureData.consentFrameworkVersion]]
 */
var TCFVersion;
(function (TCFVersion) {
    /** Version 1 of the TCF */
    TCFVersion["V1"] = "v1";
    /** Version 2 of the TCF */
    TCFVersion["V2"] = "v2";
})(TCFVersion = exports.TCFVersion || (exports.TCFVersion = {}));
/**
 * Possible values indicating whether the current environment
 * is subject to GDPR
 *
 * @see [[GDPRConsentData.subjectToGDPR]]
 */
var SubjectToGDPROptions;
(function (SubjectToGDPROptions) {
    /** Is subject to GDPR rules */
    SubjectToGDPROptions["Yes"] = "1";
    /** Is not subject to GDPR rules */
    SubjectToGDPROptions["No"] = "0";
    /** Whether subject to GDPR is undetermined */
    SubjectToGDPROptions["Unknown"] = "-1";
})(SubjectToGDPROptions = exports.SubjectToGDPROptions || (exports.SubjectToGDPROptions = {}));
var PlayerFriendlyObstructionPurpose;
(function (PlayerFriendlyObstructionPurpose) {
    PlayerFriendlyObstructionPurpose["MediaControls"] = "mediaControls";
    PlayerFriendlyObstructionPurpose["CloseAd"] = "closeAd";
    PlayerFriendlyObstructionPurpose["NotVisible"] = "notVisible";
    PlayerFriendlyObstructionPurpose["Other"] = "other";
})(PlayerFriendlyObstructionPurpose = exports.PlayerFriendlyObstructionPurpose || (exports.PlayerFriendlyObstructionPurpose = {}));
