import { useContext, useEffect } from 'react';
import {
  AppFeaturedDocument,
  AppFeaturedQuery,
  CurrentScoresDocument,
  CurrentScoresQuery,
  FeaturedSpotlight,
  FeaturedTrending,
  SpotlightContent,
  StaticTestEnv,
  useAppFeaturedQuery,
  VodList,
} from 'types/generated-types';
import { allowedEventsStore, documentVisibilityStore, hpContents } from 'apolloGraphql/mml-apollo-cache';
import { AppContext } from 'context/app-context';
import { logger } from '../logger';
import { ApolloClient, useReactiveVar } from '@apollo/client';

const LOG = logger.getLogger('useAppFeaturedData');

interface Params {
  pollInterval: number;
  queryParams: { seasonYear: number; staticTestEnv?: StaticTestEnv };
  allowed_events: number[];
}

export function useAppFeaturedData() {
  const { appConfig: config } = useContext(AppContext);
  const queryParams = {
    seasonYear: +(config?.gql.season_year ?? 0),
    staticTestEnv: config?.gql.static_test_env,
  };
  const allowedEvents = useReactiveVar(allowedEventsStore);
  useAppFeaturedDataInner({
    pollInterval: config?.gql.links.home_featured.poll_interval ?? 60,
    queryParams: queryParams,
    allowed_events: allowedEvents,
  });
}

function useAppFeaturedDataInner({ pollInterval, queryParams }: Params) {
  const isDocumentVisible = useReactiveVar(documentVisibilityStore);
  const { data: hpData } = useAppFeaturedQuery({
    variables: { ...queryParams, view: null },
    pollInterval: pollInterval * 1000,
    fetchPolicy: 'cache-and-network',
    skip: !isDocumentVisible,
  });

  useEffect(() => {
    if (!hpData?.appFeatured) return;

    const hpArray = normalizeHpContents(hpData, allowedEventsStore());

    hpContents(hpArray);
    return () => {
      LOG?.info('unsubscribing');
    };
  }, [hpData]); //FIXME:  missing allowed_events
}

function normalizeHpContents(hpData: AppFeaturedQuery, allowed_events: number[]) {
  const hpArray: (FeaturedSpotlight | FeaturedTrending | VodList)[] = [];
  const features = hpData?.appFeatured?.spotlight as FeaturedSpotlight;
  const spotlightContent = (features.content as SpotlightContent[])
    .filter(
      (item) =>
        item &&
        ((item.contentType === 'game' && filterEvents(item as SpotlightContent, allowed_events)) ||
          (item.contentType === 'event' && filterEvents(item as SpotlightContent, allowed_events)) ||
          (item.contentType === 'montage' && filterEvents(item as SpotlightContent, allowed_events)) ||
          item.contentType === 'vod' ||
          item.contentType === 'bcgPromo'),
    )
    .sort((a, b): number => {
      if (a && b) {
        return (a.sort as number) - (b.sort as number);
      }
      return -1;
    });

  const trending = hpData?.appFeatured?.trending as FeaturedTrending;
  const vodPlaylist = hpData?.appFeatured?.vodPlaylist as VodList[];

  hpArray[features?.sortObject ?? 0] = { ...features, content: spotlightContent };
  hpArray[trending?.sortObject ?? 0] = { ...trending };

  vodPlaylist?.forEach((playlist) => {
    hpArray[playlist?.sortObject ?? 0] = { ...playlist };
  });

  return hpArray;
}

const filterEvents = (item: SpotlightContent, allowed_events: number[]): boolean => {
  const bracketId = item.bracketId;

  if (bracketId) {
    if (bracketId > 703 && bracketId < 902) {
      if (allowed_events.includes(bracketId)) return true;
      return false;
    }
    return true;
  }
  return false;
};

export async function preloadAppFeaturedData(
  client: ApolloClient<object>,
  seasonYear: number,
  staticTestEnv?: StaticTestEnv,
) {
  const { data } = await client.query<AppFeaturedQuery>({
    query: AppFeaturedDocument,
    variables: { seasonYear, staticTestEnv, view: null },
  });

  hpContents(normalizeHpContents(data, allowedEventsStore()));
}

export async function preloadCurrentData(
  client: ApolloClient<object>,
  seasonYear: number,
  staticTestEnv?: StaticTestEnv,
) {
  await client.query<CurrentScoresQuery>({
    query: CurrentScoresDocument,
    variables: { seasonYear, staticTestEnv, current: true },
  });
}
