"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignalBinding = void 0;
var SignalBinding = /** @class */ (function () {
    function SignalBinding(_a) {
        var _b = _a === void 0 ? {} : _a, onDetachHandler = _b.onDetachHandler;
        this._isDetached = false;
        this._onDetachHandler =
            onDetachHandler !== null && onDetachHandler !== void 0 ? onDetachHandler : (function () {
                // do nothing
            });
    }
    Object.defineProperty(SignalBinding.prototype, "onDetachHandler", {
        set: function (value) {
            this._onDetachHandler = value;
        },
        enumerable: false,
        configurable: true
    });
    SignalBinding.prototype.detach = function () {
        if (!this._isDetached) {
            this._isDetached = true;
            this._onDetachHandler();
        }
    };
    return SignalBinding;
}());
exports.SignalBinding = SignalBinding;
