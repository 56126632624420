import { setKeyMap } from '@noriginmedia/norigin-spatial-navigation';
import { logger } from 'logger';
import { useEffect } from 'react';
import { Device, getDevice } from 'utils/getDevice';
import { Keysxbox, KeysKeyboard } from 'utils/Keys';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = logger.getLogger('useGamepadNavigation');

const device = getDevice();

// The getGamepads method on older versions of chrome/edge isn't iterable, so even after going through
// babel to convert the for each usage inside arcade machine, it blows up.
const getGamepadsOriginal = navigator.getGamepads.bind(self.navigator);
navigator.getGamepads = (): (Gamepad | null)[] => {
  const gamepads = getGamepadsOriginal();

  if (gamepads[Symbol.iterator] === undefined) {
    gamepads[Symbol.iterator] = function* (): IterableIterator<Gamepad | null> {
      const properties = Object.keys(gamepads);
      for (const i of properties) {
        yield gamepads[Number.parseInt(i)];
      }
    };
  }

  return gamepads;
};

/**
 * This handles the mapping of gamepad buttons to the spacial navigator
 */
export function useGamepadNavigation() {
  useEffect(() => {
    if (device === Device.xbox) {
      setKeyMap({
        left: [KeysKeyboard.LeftArrow, Keysxbox.LEFT_GAMEPAD_JOYSTICK, Keysxbox.LEFT_GAMEPAD_DPAD],
        up: [KeysKeyboard.UpArrow, Keysxbox.UP_GAMEPAD_JOYSTICK, Keysxbox.UP_GAMEPAD_DPAD],
        right: [KeysKeyboard.RightArrow, Keysxbox.RIGHT_GAMEPAD_JOYSTICK, Keysxbox.RIGHT_GAMEPAD_DPAD],
        down: [KeysKeyboard.DownArrow, Keysxbox.DOWN_GAMEPAD_JOYSTICK, Keysxbox.DOWN_GAMEPAD_DPAD],
        enter: [KeysKeyboard.Enter, Keysxbox.A_GAMEPAD],
      });
    }
  }, []);
}
