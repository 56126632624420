const CCPA_NON_USP_LOCATION_STRING = '1---';

/**
 * Returns the WarnerMedia Unknown Identifier.
 *
 * @returns The WMUKID
 */
export function getWMUKID() {
  if (!getGeoCountryMatch()) {
    return 'Unknown';
  }

  const WMUKID = getOneCookie('WMUKID') || null;

  if (!WMUKID) return createWMUKID();
  else return WMUKID.id;
}

/**
 * Returns the current CCPA USPAPI consent string.
 *
 * @returns Current USPAPI consent string or "1---" if not in CCPA region
 */
export function getUSPString() {
  if (!getGeoMatch()) {
    return CCPA_NON_USP_LOCATION_STRING;
  }
  return getOneCookie('usprivacy') as USPString;
}
/**
 * Returns the uspData object, containing the current spec version and the USPAPI consent string.
 *
 * @see https://github.com/InteractiveAdvertisingBureau/USPrivacy/blob/master/CCPA/USP%20API.md#getuspdata
 * @returns The uspData object as defined by the IAB spec
 */
export function getUSPData() {
  return {
    version: 1,
    uspString: getUSPString(),
  };
}

/**
 * @returns true if the user has opted out of the sale of their personal information, false otherwise.
 */
export function getUSPBoolean() {
  return getGppBoolean();
}

/**
 * @returns 1 if the user has opted out of the sale of their personal information, 0 otherwise.
 */
export function getUSPInt() {
  return getGppInt();
}

/**
 * Returns an object containing the USP API consent string, and a comscore value for ads consumption
 * @returns the USP object
 */
export function getUSPObject() {
  return {
    uspString: getUSPString(),
    comscore: +!getUSPBoolean(),
  };
}

function getGeoCountryMatch() {
  const acceptedGeoLocations = ['US', 'PR', 'VI', 'UM', ''];
  return acceptedGeoLocations.includes(self.WBD?.UserConsent.getGeoCountry() ?? '');
}

function getGeoMatch() {
  return self.WBD?.UserConsent.getGppAPIstring() !== '';
}

type Request = {
  url: string;
  method: string;
  headers?: Record<string, string>;
  payload?: unknown;
};

type ResponseParam<T> = {
  data?: T;
  status: number;
  statusText: string;
  headers: string;
};

function makeRequest<T>(request: Request) {
  return new Promise<ResponseParam<T>>((resolve, reject) => {
    try {
      const data = extractRequestPayload(request);

      const xhr = new XMLHttpRequest();
      xhr.open(request.method, request.url, true);
      xhr.setRequestHeader('Content-Type', 'application/json');
      if (request.headers && Object.keys(request.headers).length > 0) {
        for (const property in request.headers) {
          if (Object.prototype.hasOwnProperty.call(request.headers, property)) {
            xhr.setRequestHeader(property, request.headers[property]);
          }
        }
      }
      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === 4) {
          const responseParam: ResponseParam<T> = {
            status: xhr.status,
            statusText: xhr.statusText,
            headers: xhr.getAllResponseHeaders(),
          };
          if (xhr.status >= 200 && xhr.status < 300) {
            try {
              responseParam.data = JSON.parse(xhr.response) as T;
              return resolve(JSON.parse(JSON.stringify(responseParam)));
            } catch (e) {
              return resolve(xhr.response);
            }
          }
        }
      });
      xhr.addEventListener('error', () => {
        return reject({
          status: xhr.status,
          statusText: `An error occurred during the transaction`,
        });
      });
      xhr.send(data);
    } catch (e) {
      if (e instanceof Error) {
        return reject(new Error(e.message));
      }
      return reject(e);
    }
  });
}

function extractRequestPayload(request: Request) {
  if (typeof request.payload === 'string') {
    return request.payload;
  } else if (request.payload instanceof String) {
    return request.payload.valueOf();
  } else {
    return JSON.stringify(request.payload);
  }
}

function getOneCookie(cookieName: string) {
  let fullCookieName;

  // Check if cookieName is already encoded
  try {
    decodeURIComponent(cookieName);
    // already encoded
    fullCookieName = `${cookieName}=`;
  } catch (e) {
    // needs encoding
    fullCookieName = `${encodeURIComponent(cookieName)}=`;
  }

  const cookies = document.cookie.match(`(^|;)\\s*${fullCookieName}\\s*([^;]+)`);

  // decode cookie value on return
  // const cookie = cookies?.pop();
  const cookie = cookies && cookies.pop();
  if (cookie) {
    try {
      return JSON.parse(decodeURIComponent(cookie));
    } catch {
      return decodeURIComponent(cookie);
    }
  }
  return null;
}

function setCookie(cookieName: string, cookieObject: string | Record<string, string | number>, cookieDomain: string) {
  let cookieString = '';
  if (!cookieName) {
    return null;
  }
  if (!cookieObject) {
    return null;
  }
  const expiration = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000);
  if (typeof cookieObject === 'string') {
    cookieString = cookieObject;
  } else {
    cookieString = JSON.stringify(cookieObject);
  }
  if (!cookieDomain) {
    cookieDomain = self.location.hostname;
  }
  // cookie token & value need to be encoded before saving
  document.cookie = `${encodeURIComponent(cookieName)}=${encodeURIComponent(
    cookieString,
  )};expires=${expiration.toUTCString()}; domain=${cookieDomain}; samesite=Lax; path=/`;
  return cookieObject;
}

function getDomain() {
  const psmMetaData = getOneCookie('psmMetaData');
  let cookieDomain = self.location.hostname;
  if (psmMetaData?.domain) {
    cookieDomain = psmMetaData.domain;
  }
  return cookieDomain;
}

type UrlType = 'featureFlag' | 'privacy' | 'identity' | 'telemetry' | 'errors';
type EnvString = 'DEV' | 'QA' | 'PROD' | 'INTEGRATION';
type UrlObject = Record<UrlType, Record<EnvString, string>>;

const urlObject: UrlObject = {
  featureFlag: {
    DEV: 'https://wmff.warnermediacdn.com/psm_dev_full.json',
    QA: 'https://wmff.warnermediacdn.com/psm_qa_full.json',
    PROD: 'https://wmff.warnermediacdn.com/psm_prod_full.json',
    INTEGRATION: 'https://wmff.warnermediacdn.com/psm_brand_integration_config_full.json',
  },
  privacy: {
    DEV: 'https://dev.privacy.api.wmcdp.io/v1/pvc',
    QA: 'https://test.privacy.api.wmcdp.io/v1/pvc',
    PROD: 'https://privacy.api.wmcdp.io/v1/pvc',
    INTEGRATION: 'https://integration.privacy.api.wmcdp.io/v1/pvc',
  },
  identity: {
    DEV: 'https://dev.identity.api.wmcdp.io/v1/reg',
    QA: 'https://test.identity.api.wmcdp.io/v1/reg',
    PROD: 'https://identity.api.wmcdp.io/v1/reg',
    INTEGRATION: 'https://integration.identity.api.wmcdp.io/v1/reg',
  },
  telemetry: {
    DEV: 'https://dev.telemetry.api.wmcdp.io/v1/tel',
    QA: 'https://test.telemetry.api.wmcdp.io/v1/tel',
    PROD: 'https://telemetry.api.wmcdp.io/v1/tel',
    INTEGRATION: 'https://integration.telemetry.api.wmcdp.io/v1/tel',
  },
  errors: {
    DEV: 'https://dev.logs.psm.wmcdp.io',
    QA: 'https://dev.logs.psm.wmcdp.io',
    PROD: 'https://logs.psm.wmcdp.io',
    INTEGRATION: 'https://dev.logs.psm.wmcdp.io',
  },
};

function getUrl(environment: string, urlType: UrlType) {
  const uppercaseEnv = environment.toUpperCase();
  let envString: EnvString;
  if (uppercaseEnv === 'DEV' || uppercaseEnv === 'DEVELOPMENT') {
    envString = 'DEV';
  } else if (
    uppercaseEnv === 'TEST' ||
    uppercaseEnv === 'TESTING' ||
    uppercaseEnv === 'STAGE' ||
    uppercaseEnv === 'STAGING' ||
    uppercaseEnv === 'QA'
  ) {
    envString = 'QA';
  } else if (uppercaseEnv === 'PROD' || uppercaseEnv === 'PRODUCTION') {
    envString = 'PROD';
  } else if (uppercaseEnv === 'INTEGRATION') {
    envString = 'INTEGRATION';
  } else {
    envString = 'PROD';
  }

  const urlString = urlObject[urlType][envString];
  return urlString;
}

async function sendPrivacyEvent(optOut: boolean) {
  setCookie('retryPrivacy', 'false', getDomain());
  const psmMetaData = getOneCookie('psmMetaData');
  const retryIds = getOneCookie('psmRetryExternalIds') || false;
  let wmukid;
  // If no wmukid param passed, try and get the ID from the cookie itself
  if (!wmukid) {
    try {
      wmukid = getOneCookie('WMUKID').id;
    } catch {
      wmukid = getWMUKID();
    }
  }
  // handles user-consent calling ccpaDoNotShare/ccpaShareData before page fully loads
  if (!wmukid || wmukid === undefined) {
    setCookie('retryPrivacy', 'true', getDomain());
    return;
  }
  if (retryIds) {
    setCookie('retryPrivacy', 'true', getDomain());
    return 'Privacy not registered: External IDs not generated yet.';
  }

  type Params = { psmMetaData?: string; wmukid?: string };

  function createBaseEvent(params: Params = {}) {
    const { psmMetaData = getOneCookie('psmMetaData'), wmukid } = params;

    let wmukidCookie = wmukid;

    // If no wmukid param passed, try and get the ID from the cookie itself
    if (!wmukidCookie) {
      wmukidCookie = getOneCookie('WMUKID')?.id;
    }

    type BaseObject = {
      appId: string;
      wmukid: string;
      wmhhid: string;
      wminid: string;
      attuid: string;
      timestamp: number;
      brand: string | null;
      domain: string | null;
      userAgent: string;
      platform: string;
      library: {
        name: string;
        version: string;
      };
      ids?: Record<string, string>;
      optOut?: boolean;
    };

    // Fields shared across all Doppler payloads
    const baseObject: BaseObject = {
      appId: psmMetaData?.appId || 'Unknown',
      wmukid: wmukidCookie || 'Unknown',
      wmhhid: getOneCookie('wmhhid'), // currently null
      wminid: getOneCookie('wminid'), // currently null
      attuid: getOneCookie('firstpartyuid'), // currently null
      timestamp: Date.now(),
      brand: psmMetaData?.brand || null,
      domain: psmMetaData?.domain || null,
      userAgent: self.navigator.userAgent.toString(),
      platform: 'web',
      library: {
        name: 'Psm',
        version: '1.2.4',
      },
    };

    // Only attach ids object if there are IDs in the object
    const ids = getOneCookie('psmExternalIds') || {};

    if (Object.keys(ids).length !== 0) {
      if (ids.kruxid && ids.kruxid !== 'retry') {
        baseObject.ids = ids;
      }
    }

    return baseObject;
  }
  const privacyObject = createBaseEvent({ psmMetaData, wmukid });
  const env = psmMetaData?.environment || 'PROD';
  const privacyUrl = getUrl(env, 'privacy');
  privacyObject.optOut = optOut;
  try {
    const resp = await makeRequest({ url: privacyUrl, method: 'POST', payload: privacyObject });
    return resp;
  } catch (error) {
    setCookie('retryPrivacy', 'true', getDomain());
    console.error('pvc', 'sendPrivacyEvent', error);
    return error;
  }
}

type USPBit = 'Y' | 'N' | '-';
type USPString = `1${USPBit}${USPBit}${USPBit}` | '1---';

function generateUSPString(specCharacter: '1', opportunity: USPBit, optOut: USPBit, LSPA: USPBit): USPString {
  const uspString = `${specCharacter}${opportunity}${optOut}${LSPA}` as const;
  return uspString;
}

function setUSPObject(uspString: string) {
  let uspObject = {
    uspString,
    comScore: 1,
  };
  if (uspString === '1YYN') {
    uspObject = {
      uspString,
      comScore: 0,
    };
  }
  setCookie('uspObject', uspObject, getDomain());
}

function setUSPDataCookie(uspString: string, spec: string) {
  const uspData = {
    version: parseInt(spec, 10) || spec,
    uspString,
  };
  setCookie('uspData', uspData, getDomain());
}

function setUSPString(uspString: string) {
  setCookie('usprivacy', uspString, getDomain());
}

function generateUUID() {
  const uuid = new Array(36);
  for (let i = 0; i < 36; i++) {
    uuid[i] = Math.floor(Math.random() * 16);
  }
  uuid[14] = 4; // set bits 12-15 of time-high-and-version to 0100
  uuid[19] = uuid[19] &= ~(1 << 2); // set bit 6 of clock-seq-and-reserved to zero
  uuid[19] = uuid[19] |= 1 << 3; // set bit 7 of clock-seq-and-reserved to one
  uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
  return uuid.map((x) => x.toString(16)).join('');
}

function createWMUKID() {
  const WMUKID = generateUUID();

  setCookie('WMUKID', { id: WMUKID, version: 0.1, timestamp: new Date().toISOString() }, getDomain());

  return WMUKID;
}

/**
 * Initializes the privacy utils
 *
 * @returns the uspString
 */
export async function initPrivacy() {
  const existingUSPString = getUSPString();
  const uspBoolean = getUSPBoolean();
  const retryPrivacy = getOneCookie('retryPrivacy') || false;
  if (existingUSPString) {
    console.log('Privacy cookie already set');
    if (retryPrivacy && uspBoolean != null) {
      sendPrivacyEvent(uspBoolean);
    }
    return existingUSPString;
  } else {
    //   specCharacter, opportunity, optOut, LSPA
    const uspString = getUSPString();
    setUSPObject(uspString);
    setUSPDataCookie(uspString, '1');
    return uspString;
  }
}

async function updateConsent(optOut: boolean) {
  const previousOptedOut = getGppBoolean();

  await self.OneTrust.UpdateConsent('Category', `ven:${+!optOut as 1 | 0}`);

  if (!getGeoMatch()) return;

  const newOptedOut = getGppBoolean();
  if (previousOptedOut === newOptedOut) return;

  sendPrivacyEvent(optOut);
  const uspString = generateUSPString('1', 'Y', newOptedOut ? 'Y' : 'N', 'N');
  setUSPString(uspString);
  setUSPObject(uspString);
  setUSPDataCookie(uspString, '1');

  return newOptedOut;
}

/**
 * Sets the user as opted in to selling their data.
 * @returns `true` if we will not sell their data
 */
export function gppShareData() {
  return updateConsent(false);
}

/**
 * Sets the user as opted out of selling their data.
 * @returns `true` if we will not sell their data
 */
export function gppDoNotShare() {
  return updateConsent(true);
}

/**
 * Returns an integer {`1 | 0`} that determines if the user is opted out of selling their personal data.
 * @returns `0` if the user is opted in, and `1` if the user is opted out.
 */
export function getGppInt() {
  return +getGppBoolean();
}

/**
 * Returns a boolean that determines if the user is opted out of selling their personal data.
 * @returns
 */
export function getGppBoolean() {
  const groups = new URLSearchParams(getOneCookie('OptanonConsent')).get('groups')?.split(',');
  const vendorsEnabled = groups?.find((key) => key.startsWith('ven'))?.split(':')[1];

  if (vendorsEnabled == null) return false;
  return !+vendorsEnabled;
}
