import { TveLogos } from './useAuthWrapper';
import type { Provider } from '@top/auth-clientless-web-lite';
import { TelemetryUserProps } from '../../../state-machines/analytic-states';

export enum AuthStateNames {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  AUTHENTICATED = 'AUTHENTICATED',
  AUTHORIZED = 'AUTHORIZED',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  ERROR = 'ERROR',
}

export type AuthState = {
  state: AuthStateNames;
  messageId?: string | null;
  logos?: TveLogos;
  regcode?: string;
  provider?: Provider;
  userProperties?: Partial<TelemetryUserProps>;
  initializing?: boolean;
};

export const AuthStates: { [state in AuthStateNames]: AuthState } = {
  UNAUTHENTICATED: {
    state: AuthStateNames.UNAUTHENTICATED,
  },

  AUTHENTICATED: {
    state: AuthStateNames.AUTHENTICATED,
  },

  NOT_AUTHORIZED: {
    state: AuthStateNames.NOT_AUTHORIZED,
  },

  AUTHORIZED: {
    state: AuthStateNames.AUTHORIZED,
  },

  ERROR: {
    state: AuthStateNames.ERROR,
  },
};

export const AUTH_STATE_INITIAL: AuthState = {
  state: AuthStateNames.UNAUTHENTICATED,
  initializing: true,
};
