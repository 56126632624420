import { Device, getDevice, getDeviceCaptionsEnabled, getModelName } from 'utils/getDevice';
import { isWebos4 } from 'utils/webos/webos-utils';

class PerformanceFeatureFlagUtil {
  private _device: Device;
  private _performanceFlags: PerformanceFlags | undefined;
  private _webosDeviceInfo: DeviceInfo | undefined;

  constructor(device: Device, _performanceFlags?: PerformanceFlags) {
    this._device = device;
    this._performanceFlags = _performanceFlags;

    // pre fetch and cache the device captions setting on lg
    if (this._device === Device.lgtv) {
      getDeviceCaptionsEnabled();
    }
  }

  set performanceFlags(value: PerformanceFlags) {
    this._performanceFlags = value;
  }

  set webosDeviceInfo(value: DeviceInfo | undefined) {
    this._webosDeviceInfo = value;
  }

  shouldAllowSkeletonLoadingAnimation(): boolean {
    if (this._device === Device.lgtv) {
      return !this._isLowEndWebosDevice();
    } else {
      const disableSkeletonLoading = !!this._getFlagsForDevice(this._device)?.disableSkeletonLoading?.find(
        (value) => value === getModelName(),
      );
      return !disableSkeletonLoading;
    }
  }

  shouldAllowPulseIconAnimation(): boolean {
    return this._device !== Device.lgtv || !this._isLowEndWebosDevice();
  }

  shouldAllowPrism(): boolean {
    if (this._device !== Device.lgtv) return true;

    return !this._isLowEndWebosDevice();
  }

  shouldUseLowerBitrate(): boolean {
    return true;
  }

  getMaxBitrate(): number | null {
    if (this._device === Device.lgtv && this._isLowEndWebosDevice()) {
      return 2500;
    } else {
      if (this._device === Device.xbox || this._device === Device.lgtv) {
        // TODO:  temporarily allowing these to be unlimited in the absence of having this be config driven
        // for us to be able to dynamically control the max bitrate on individual device models.  Should update this
        // for 2024 to actually be config driven.
        return null;
      } else if (this._device === Device.firetv) {
        const maxBitrate = this._getFlagsForDevice(this._device)?.maxBitrate?.[getModelName()];
        // Allowing firetv to have an uncapped bitrate as part of an experiment
        // Not trying to make the logic too pretty to keep it simple/reduce any chance of screwing up the logic
        return maxBitrate || null;
      } else {
        const maxBitrate = this._getFlagsForDevice(this._device)?.maxBitrate?.[getModelName()];
        // 5500 is currently the highest bitrate before we reach 60fps streams
        return maxBitrate || 5500;
      }
    }
  }

  getMultigameMaxBitrate(): number | null {
    const maxBitrate = this._getFlagsForDevice(this._device)?.multigameMaxBitrate?.[getModelName()];

    // Default max bitrate to 2000 Kbps when multigame is active
    return maxBitrate || 2000;
  }

  shouldLimitFreeViewUpdateBroadcasts(): boolean {
    return this._device === Device.lgtv && this._isLowEndWebosDevice();
  }

  shouldAllowMagicRemote(): boolean {
    return this._device === Device.lgtv && !this._isLowEndWebosDevice();
  }

  shouldAllowGameCenterPollingHack(): boolean {
    return this._device === Device.lgtv && this._isLowEndWebosDevice();
  }

  private _getFlagsForDevice(deviceType: Device): PerformanceFlagOptions | null | undefined | Record<string, never> {
    return this._performanceFlags?.[deviceType];
  }

  private _isLowEndWebosDevice(): boolean {
    return isWebos4(this._webosDeviceInfo);
  }
}

// Static list of device/flag combinations based on conviva metrics.  May try to move
// this to app_controls in the future.
const performance: PerformanceFlags = {
  [Device.xbox]: {},
  [Device.androidtv]: {
    maxBitrate: {
      'Bravia 4K GB': 2000,
      'Bravia 4K GB ATV3': 2000,
      'Bravia VU1': 2000,
      'Beyond TV': 2000,
      'Bravia 4K 2015': 2000,
      'Smart TV Pro': 2000,
      'Stream 4K': 2000,
      '2K TV Stick': 2000,
    },
    multigameMaxBitrate: {
      'Bravia 4K GB': 1000,
      'Bravia 4K GB ATV3': 1000,
      'Bravia VU1': 1000,
      'Beyond TV': 1000,
      'Bravia 4K 2015': 1000,
      'Smart TV Pro': 1000,
      'Stream 4K': 1000,
      '2K TV Stick': 1000,
    },
    disableSkeletonLoading: [
      'Bravia 4K GB',
      'Bravia 4K GB ATV3',
      'Bravia VU1',
      'Beyond TV',
      'Bravia 4K 2015',
      'Smart TV Pro',
      'Stream 4K',
      '2K TV Stick',
    ],
  },
  [Device.firetv]: {
    maxBitrate: {
      AFTSSS: 2000,
      AFTSS: 2000,
      AFTKA: 2000,
      AFTEAMR311: 2000,
      AFTBAMR311: 2000,
      AFTKMST12: 2000,
      'Fire TV (3rd Gen)': 2000,
      AFTJMST12: 2000,
      AFTA: 2000,
      AFTB: 2000,
      'Fire TV Stick': 2000,
      AFTRS: 2000,
      AFTT: 2000,
      AFTM: 2000,
      AFTLFT962X3: 2000,
      AFTS: 2000,
      AFTN: 2000,
    },
    multigameMaxBitrate: {
      AFTSSS: 1000,
      AFTSS: 1000,
      AFTKA: 1000,
      AFTEAMR311: 1000,
      AFTBAMR311: 1000,
      AFTKMST12: 1000,
      'Fire TV (3rd Gen)': 1000,
      AFTJMST12: 1000,
      AFTA: 1000,
      AFTB: 1000,
      'Fire TV Stick': 1000,
      AFTRS: 1000,
      AFTT: 1000,
      AFTM: 1000,
      AFTLFT962X3: 1000,
      AFTS: 1000,
      AFTN: 1000,
    },
    disableSkeletonLoading: [
      'AFTSSS',
      'AFTSS',
      'AFTKA',
      'AFTEAMR311',
      'AFTKMST12',
      'Fire TV (3rd Gen)',
      'AFTJMST12',
      'AFTA',
      'AFTB',
      'Fire TV Stick',
      'AFTRS',
      'AFTT',
      'AFTM',
      'AFTLFT962X3',
      'AFTS',
      'AFTN',
    ],
  },
  [Device.lgtv]: {},
};

type PerformanceFlagOptions = {
  maxBitrate: { [modelName: string]: number };
  multigameMaxBitrate: { [modelName: string]: number };
  disableSkeletonLoading: string[];
};

type PerformanceFlags = {
  [key in Device]?: PerformanceFlagOptions | null | undefined | Record<string, never>;
};

const performanceFeatureFlagUtil = new PerformanceFeatureFlagUtil(getDevice(), performance);

export { performanceFeatureFlagUtil as PerformanceFeatureFlagUtil };
