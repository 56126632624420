import { Device } from 'utils/getDevice';
import { wrap } from 'comlink';
import type { IAppWorker } from './IAppWorker';
import { AppCompatWorker } from './app-compat.worker';
import AppWorker from 'web-workers';

export class AppWorkerFactory {
  private static _instance: AppWorkerFactory;

  protected constructor() {
    // prevent public instantiation
  }

  public static get Instance() {
    return this._instance ?? (this._instance = new this());
  }

  public create(device: Device) {
    if (device === Device.lgtv) {
      return AppCompatWorker;
    } else {
      return wrap<IAppWorker>(AppWorker);
    }
  }
}

export const AppWorkerFactoryInstance = AppWorkerFactory.Instance;
