import { MarqueeState, MarqueeStateNames, MarqueeStates } from './marquee-states';
import { StatSectionStates, StatsSectionState, StatsSectionStateNames } from './stats-section-states';
import { VideoLinksState } from './video-links-states';

export type GameCenterState = {
  bracketId: number;
  marquee: MarqueeState;
  statsSection: StatsSectionState;
  gameswitcherSection: boolean;
  noLiveGames: boolean;
  videoLinksState: VideoLinksState;
  gameState?: string;
  broadcaster?: string | null;
};

export const GAME_CENTER_STATE_INITIAL: GameCenterState = {
  bracketId: -1,
  marquee: MarqueeStates[MarqueeStateNames.UPCOMING],
  statsSection: StatSectionStates[StatsSectionStateNames.UPCOMING],
  gameswitcherSection: false,
  noLiveGames: true,
  videoLinksState: {
    condensedVideoButton: false,
    previewVideoButton: false,
    recapVideoButton: false,
  },
};

export type MultiGameModalState =
  | { open: false; bracketId: null; callback: null }
  | { open: true; bracketId: number; callback: (isSelectionMultiGame: boolean | null) => void };

export const MULTI_GAME_MODAL_STATE_INITIAL: MultiGameModalState = {
  open: false,
  bracketId: null,
  callback: null,
};

export type FullScreenExcitementAlertState =
  | { open: false; bracketId: null; hasAutoDismissed: boolean }
  | { open: false; bracketId: number; hasAutoDismissed: boolean }
  | { open: true; bracketId: number; hasAutoDismissed: boolean };

export const FULL_SCREEN_EXCITEMENT_ALERT_STATE_INITIAL: FullScreenExcitementAlertState = {
  open: false,
  bracketId: null,
  hasAutoDismissed: false,
};

export type GetAccessModalState = { open: boolean; isMultiGame: boolean };

export const GET_ACCESS_MODAL_STATE_INITIAL: GetAccessModalState = { open: false, isMultiGame: false };
