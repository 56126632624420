"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Log = exports.LogWrapper = void 0;
/**
 * Logging functionality entry point. This is a convenience wrapper
 * which exposes the desired public methods of the LogManager.
 */
var LogWrapper = /** @class */ (function () {
    function LogWrapper() {
    }
    LogWrapper.init = function (value) {
        this._log = value;
        this._log.setEnabled(LogWrapper._enabled);
    };
    LogWrapper.prototype.setEnabled = function (value) {
        LogWrapper._enabled = value;
        if (LogWrapper._log) {
            LogWrapper._log.setEnabled(LogWrapper._enabled);
        }
    };
    LogWrapper._enabled = true;
    return LogWrapper;
}());
exports.LogWrapper = LogWrapper;
var StaticLog = new LogWrapper();
exports.Log = StaticLog;
