import Link from '@mui/material/Link';
import { appReady } from 'apolloGraphql/mml-apollo-cache';
import LoadingBar from 'components/LoadingBars/LoadingBars';
import { FocusKeys } from 'components/SpacialNavigation/focusKeys';
import useFocusItem from 'hooks/useFocusItem';
import { useIsOnline } from 'hooks/useIsOnline';
import { FC, useCallback, useEffect, useState } from 'react';
import { Device, getDevice } from 'utils/getDevice';
import classNames from 'utils/classNames';
import LoadingAnimation, { AnimationType } from './LoadingAnimation/LoadingAnimation';
import { Background, Connecting, Connecting_Background, LoadingPage, Online, Overlay, Presented } from './styles';
import { logger } from '../../logger/index';
import AppPresentedBar from './AppPresentedBar';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = logger.getLogger('LoadingScreen');
const device = getDevice();

type LoadingScreenProps = {
  onAnimationComplete?: () => void;
};

const LoadingScreen: FC<LoadingScreenProps> = ({ onAnimationComplete }) => {
  const online = useIsOnline();
  const [isConnecting, setIsConnecting] = useState(false);
  const [shouldRetry, setShouldRetry] = useState(false);

  // don't allow user to navigate away
  const onArrowPress = useCallback(() => false, []);

  const checkOnlineStatus = useCallback(() => {
    setIsConnecting(true);
    setTimeout(() => {
      setIsConnecting(false);
    }, 3000);
  }, []);

  const delayPresented = useCallback((): void => {
    onAnimationComplete?.();
    if (online) {
      setTimeout(() => {
        appReady({ ...appReady(), minTimeSponsorShown: true });
      }, 3000);
    }
  }, [onAnimationComplete, online]);

  useEffect(() => {
    if (!online) {
      appReady({ ...appReady(), minTimeSponsorShown: false });
      setShouldRetry(true);
    }
    if (shouldRetry && online) {
      setShouldRetry(false);
      self.location.assign('/');
    }
  }, [online, shouldRetry]);

  const { ref, focusSelf, focused } = useFocusItem({
    focusKey: FocusKeys.LoadingRetryButton,
    onArrowPress: onArrowPress,
    onEnterPress: checkOnlineStatus,
  });
  useEffect(() => {
    if (ref.current) focusSelf();
  }, [focusSelf, ref]);

  return (
    <LoadingPage>
      <Background>
        <LoadingAnimation
          animationType={device === Device.lgtv ? AnimationType.Lottie : AnimationType.Video}
          onComplete={delayPresented}
        />
      </Background>
      <Overlay>
        <Online>
          {!online ? (
            <div>
              <div className="ConnCaption-1LVL-5L">You&apos;re experiencing internet connection issues.</div>
              {!isConnecting ? (
                <Link
                  ref={ref}
                  component="button"
                  color="inherit"
                  onClick={checkOnlineStatus}
                  className={classNames({ 'nav-selected': focused })}
                >
                  <span className="ConnButtonPrimaryDKMD">Retry</span>
                </Link>
              ) : (
                <Connecting>
                  <Connecting_Background />
                  <div>
                    <div className="ConnSubtitle-1LVL-5L">Connecting...</div>
                    <LoadingBar />
                  </div>
                </Connecting>
              )}
            </div>
          ) : (
            <LoadingBar />
          )}
        </Online>
        <Presented>
          <AppPresentedBar />
        </Presented>
      </Overlay>
    </LoadingPage>
  );
};

export default LoadingScreen;
