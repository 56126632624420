"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerConfigBuilder = void 0;
var PlayConfigBuilder_1 = require("./PlayConfigBuilder");
var SetupConfigBuilder_1 = require("./SetupConfigBuilder");
/**
 * Helper class to create player configuration data used for initializing
 * the player as well as updating player configuration in an ad-hoc way and
 * per play
 */
var PlayerConfigBuilder = /** @class */ (function () {
    function PlayerConfigBuilder() {
    }
    /**
     * Specifies that the object to create will be for initializing the player
     *
     * @see [[forUpdate]] to update player configuration after the player has initialized
     */
    PlayerConfigBuilder.forSetup = function () {
        return SetupConfigBuilder_1.SetupConfigBuilder.create();
    };
    /**
     * Specifies that the object to create will be for configuration
     * during a play call
     */
    PlayerConfigBuilder.forPlay = function () {
        return PlayConfigBuilder_1.PlayConfigBuilder.create();
    };
    /**
     * Specifies that the object to create will be for updating player configuration
     * after the player has been initialized
     */
    PlayerConfigBuilder.forUpdate = function () {
        return PlayConfigBuilder_1.PlayConfigBuilder.create();
    };
    return PlayerConfigBuilder;
}());
exports.PlayerConfigBuilder = PlayerConfigBuilder;
