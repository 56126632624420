import { logger } from 'logger';
import { getWebOSApi, WebOSError } from './webos/webOSApi';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = logger.getLogger('getDevice');

export enum Device {
  androidtv = 'androidtv',
  firetv = 'firetv',
  xbox = 'xbox',
  lgtv = 'lgtv',
}

export const convivaDevices: Record<Device, string> = {
  [Device.xbox]: 'Xbox',
  [Device.androidtv]: 'AndroidTV',
  [Device.firetv]: 'FireTV',
  [Device.lgtv]: 'LG TV',
};

type CachedValues = {
  adId: string;
  adIdDirty: boolean;
  device: Device;
  deviceDirty: boolean;
  deviceCaptionsEnabled?: boolean | null;
  deviceId: string;
  modelName: string;
};

const cachedValues: CachedValues = {
  adId: '',
  adIdDirty: true,
  device: Device.xbox,
  deviceDirty: true,
  deviceId: '',
  modelName: '',
};

function _getDeviceID(): string {
  if (cachedValues.deviceId !== '' && cachedValues.deviceId != null) {
    return cachedValues.deviceId;
  }

  let deviceId = '';
  if (self.UWP && self.UWP.deviceID) {
    deviceId = self.UWP.deviceID;
  } else if (self.Android !== undefined) {
    const android = self.Android;
    deviceId = android.getDeviceId();
  } else if (isLgTv()) {
    getWebOSApi()
      ?.getDeviceId()
      .then((result) => (cachedValues.deviceId = result))
      .catch((reason: WebOSError) => LOG.error(`Error code: ${reason.errorCode}\nError Message: ${reason.errorText}`));
  }
  cachedValues.deviceId = deviceId;
  return deviceId;
}

function _getAdID(): string {
  let adId = '';
  if (self.UWP && self.UWP.adID) {
    adId = self.UWP.adID;
  } else if (self.Android !== undefined) {
    adId = self.Android.getAdvertisingId();
  }

  return adId;
}

function _getDevice(): Device {
  if (isAndroid()) {
    const android = self.Android;
    if (android.getDeviceType().toLowerCase() === 'firetv') {
      return Device.firetv;
    } else if (android.getDeviceType().toLowerCase() === 'androidtv') {
      return Device.androidtv;
    }
  } else if (isLgTv()) {
    LOG.info('successful webOS detection');
    return Device.lgtv;
  }

  return Device.xbox;
}

function isAndroid(): boolean {
  return self.Android !== undefined;
}

function isLgTv(): boolean {
  return /web0s/i.test(self.navigator.userAgent);
}

export function isXbox(): boolean {
  return self.UWP !== undefined || Object.keys(self.UWP).length > 0;
}

export function getDevice(): Device {
  if (!cachedValues.deviceDirty) {
    return cachedValues.device;
  }
  const device = _getDevice();
  cachedValues.device = device;
  cachedValues.deviceDirty = false;

  return device;
}

export function getDeviceID(): string {
  const deviceID = _getDeviceID();
  return deviceID;
}

export function getAdID(): string {
  if (!cachedValues.adIdDirty) {
    return cachedValues.adId;
  }
  cachedValues.adId = _getAdID();
  cachedValues.adIdDirty = false;

  return cachedValues.adId;
}

export function invalidateAdId() {
  cachedValues.adIdDirty = true;
}

function _getModelName(): string {
  if (cachedValues.modelName !== '' && cachedValues.modelName != null) {
    return cachedValues.modelName;
  }

  let modelName = '';
  if (self.UWP && self.UWP.deviceID) {
    modelName = self.UWP.model ?? '';
  } else if (self.Android !== undefined) {
    const android = self.Android;
    modelName = android.getModelName();
  } else if (isLgTv()) {
    getWebOSApi()
      ?.getDeviceInfo()
      .then((result) => (cachedValues.modelName = result.modelName))
      .catch((reason: WebOSError) => LOG.error(`Error code: ${reason.errorCode}\nError Message: ${reason.errorText}`));
  }
  LOG.debug(`modelName: ${modelName}`);
  cachedValues.modelName = modelName;
  return modelName;
}

export function getModelName(): string {
  const modelName = _getModelName();
  return modelName;
}

function _getDeviceCaptionsEnabled(): CachedValues['deviceCaptionsEnabled'] {
  switch (getDevice()) {
    case Device.androidtv:
    case Device.firetv:
      cachedValues.deviceCaptionsEnabled = self.Android.getSystemCaptionsEnabled();
      return cachedValues.deviceCaptionsEnabled;

    case Device.lgtv:
      getWebOSApi()
        ?.getSystemCaptionsEnabled()
        .then((result) => (cachedValues.deviceCaptionsEnabled = result))
        .catch((reason: WebOSError) =>
          LOG.error(
            `Error code: ${(reason as WebOSError).errorCode}\nError Message: ${(reason as WebOSError).errorText}`,
          ),
        );
      return cachedValues.deviceCaptionsEnabled;

    /**
     * currently not supported on xbox
     * @see {@link https://stackoverflow.com/questions/42649887/check-if-user-enabled-closed-captions-in-uwp-xbox-app}
     */
    case Device.xbox:
      return null;

    default:
      return null; // not available on this platform
  }
}

export function getDeviceCaptionsEnabled() {
  if (cachedValues.deviceCaptionsEnabled != null) {
    return cachedValues.deviceCaptionsEnabled;
  }

  return _getDeviceCaptionsEnabled();
}
