import useEventListener from 'hooks/useEventListener';
import { FC, useCallback, useRef } from 'react';
import { asset } from 'utils/assets';

export type VideoLoadingAnimationProps = {
  onComplete: () => void;
};

const VideoLoadingAnimation: FC<VideoLoadingAnimationProps> = ({ onComplete }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const onVideoEnded = useCallback(() => {
    onComplete();
  }, [onComplete]);

  useEventListener('ended', onVideoEnded, videoRef.current);

  return (
    <video ref={videoRef} autoPlay muted={true} preload="auto">
      <source src={asset('/assets/loadingPage/splashAnimation.mp4')} type="video/mp4" />
    </video>
  );
};

export default VideoLoadingAnimation;
