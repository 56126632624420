import { appReady, documentVisibilityStore, pageInfoState } from 'apolloGraphql/mml-apollo-cache';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useApolloClient, useReactiveVar } from '@apollo/client';
import loadable from '@loadable/component';
import { useGoBack } from 'hooks/useGoBack';
import { InitAndroidBridge } from '../../bridge/init-android-bridge';
import { useKillLogs } from '../../hooks/useKillLogs';

import { useFocusApp } from '../../hooks/useFocusApp';
import { skipSplashScreen } from 'utils/assets';
import { useVisibilityChange } from '../../utils/handleVisibilityChanged';
import { usePreloadRoute } from 'hooks/usePreloadRoute';
import { useAppContext } from 'context/app-context';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import AppUpdateDialogue from 'components/AppUpdateDialogue/AppUpdateDialogue';
import { logger } from 'logger';
import { PerformanceFeatureFlagUtil } from 'utils/PerformanceFeatureFlagUtil';
import { useNewRelicData } from 'hooks/useNewRelicData';
import { initPrivacy } from 'utils/privacy';
import { invalidateMarketingCloudVisitorID } from 'utils/heartbeatWrapper';
import { invalidateAdId } from 'utils/getDevice';
import { useAnalytics } from 'hooks/useAnalytics';
import { getValue, setValue } from 'utils/localStorage';
import { loadableRoutes } from 'routes';
import { preloadAppFeaturedData, preloadCurrentData } from 'hooks/useAppFeaturedData';

const DOM_NODE = document.getElementById('root') as HTMLElement;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = logger.getLogger('Shell');

const NavigationShell = loadable(() => import(/* webpackChunkName: "NavigationShell" */ './NavigationShell'));
const FeaturePageLoadingComponent = loadable(
  () => import(/* webpackChunkName: "FeaturePageLoadingComponent" */ 'pages/Shell/FeaturePageLoadingComponent'),
);

initPrivacy();

const Root: FC = () => {
  useAnalytics();
  useVisibilityChange();
  useGoBack();
  const { appConfig: config, webosDeviceInfo } = useAppContext();
  const { disableLogs } = useKillLogs();
  const focusApp = useFocusApp();
  const [startApp, setStartApp] = useState(false);
  const { initialQueriesComplete, minTimeSponsorShown } = useReactiveVar(appReady);
  const pageInfo = useReactiveVar(pageInfoState);
  const visibilityChanged = useReactiveVar(documentVisibilityStore);

  const scrollTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  usePreloadRoute();
  useNewRelicData();

  const apolloClient = useApolloClient();

  const preloadDataAndJs = useCallback(() => {
    Object.values(loadableRoutes).forEach(({ Component }) => {
      Component.preload();
    });
    preloadAppFeaturedData(apolloClient, +(config?.gql.season_year ?? 0), config?.gql.static_test_env);
    preloadCurrentData(apolloClient, +(config?.gql.season_year ?? 0), config?.gql.static_test_env);
  }, [apolloClient, config?.gql.season_year, config?.gql.static_test_env]);

  useEffect(() => {
    if (getValue('shareData') === null) {
      setValue('shareData', 'enable');
    }
  }, []);

  useEffect(() => {
    disableLogs(true);
  }, [disableLogs]);

  useEffect(() => {
    invalidateMarketingCloudVisitorID();
    invalidateAdId();
  }, [visibilityChanged]);

  useEffect(() => {
    InitAndroidBridge();
  }, []);

  const onUpdateCheck = (requiresMandatoryVersion: boolean) => {
    if (requiresMandatoryVersion) {
      setStartApp(false);
    } else {
      setStartApp(true);
    }
  };

  useEffect(() => {
    if (initialQueriesComplete && minTimeSponsorShown) {
      if (typeof self.external !== 'undefined' && 'notify' in self.external) {
        // @ts-expect-error: external cannot be declared since it's readonly from TS.
        self.external.notify('pageLoaded');
        focusApp.activate(true);
      }
    }
  }, [focusApp, initialQueriesComplete, minTimeSponsorShown]);

  useEffect(() => {
    if (!PerformanceFeatureFlagUtil.shouldAllowMagicRemote()) return;

    // Enable LG Magic Remote pointer vertical scrolling
    const onMouseMove = (event: MouseEvent) => {
      const processMouseScroll = (changeY: number) => {
        // Continue scrolling while mouse is resting near the edge until top/bottom of page is reached
        if (
          (changeY < 0 && DOM_NODE.scrollTop > 0) ||
          (changeY > 0 && self.innerHeight + DOM_NODE.scrollTop < DOM_NODE.scrollHeight)
        ) {
          DOM_NODE.scrollTo(DOM_NODE.scrollLeft, DOM_NODE.scrollTop + changeY);
          scrollTimer.current = setTimeout(() => {
            processMouseScroll(changeY);
          }, 30);
        }
      };

      scrollTimer.current && clearTimeout(scrollTimer.current);
      const mouseScrollEdgeDistance = self.innerHeight * 0.1;
      if (event.clientY < mouseScrollEdgeDistance) {
        processMouseScroll(-20);
      } else if (self.innerHeight - event.clientY < mouseScrollEdgeDistance) {
        processMouseScroll(20);
      }
    };
    DOM_NODE.addEventListener('mousemove', onMouseMove);
    return () => {
      DOM_NODE.removeEventListener('mousemove', onMouseMove);
    };
  }, [webosDeviceInfo]);

  useEffect(() => {
    NavigationShell.preload();
  }, []);

  return (
    <>
      {skipSplashScreen() || (startApp && initialQueriesComplete && minTimeSponsorShown) ? (
        <NavigationShell fallback={<FeaturePageLoadingComponent pathname={pageInfo.path} />} />
      ) : (
        <LoadingScreen onAnimationComplete={preloadDataAndJs} />
      )}
      <AppUpdateDialogue onUpdateCheck={onUpdateCheck} />
    </>
  );
};

export default Root;
