import { useEffect } from 'react';
import { Device, getDeviceID } from 'utils/getDevice';

const useInitialScripts = (): void => {
  useEffect(() => {
    if (getDeviceID() !== Device.xbox) return;

    const script = document.createElement('script');
    const scriptText = document.createTextNode(`
    if(self.Windows && self.Windows.UI && self.Windows.UI.ViewManagement){
      let appView = Windows.UI.ViewManagement.ApplicationView.getForCurrentView();
      appView.setDesiredBoundsMode(Windows.UI.ViewManagement.ApplicationViewBoundsMode.useCoreWindow);
    }`);

    script.appendChild(scriptText);
    document.head.appendChild(script);

    return (): void => {
      document.head.removeChild(script);
    };
  }, []);
};

export default useInitialScripts;
