import { useReactiveVar } from '@apollo/client';
import type { AuthConfiguration, EnvironmentName } from '@top/auth-clientless-web-lite';
import { appReady } from 'apolloGraphql/mml-apollo-cache';
import { logger } from 'logger';
import { useMemo } from 'react';
import { useAppContext } from '../../../context/app-context';
import { Device, getDevice } from '../../../utils/getDevice';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = logger.getLogger('useConfigBuilder');

const device = getDevice();

export const useAuthConfigBuilder = (): Promise<AuthConfiguration | null> => {
  const config = useAppContext().appConfig;
  const { initialQueriesComplete, minTimeSponsorShown } = useReactiveVar(appReady);

  // creates the config for auth library
  const authConfig = useMemo(async () => {
    if (!config || !initialQueriesComplete || !minTimeSponsorShown) {
      return null;
    }
    const configUrl = new URL(config.video.auth.mvpd_config_url, self.location.href);
    const deviceName = device === Device.xbox ? `${device}OneUWP` : device;

    // Delay loading lib until after app launch
    const { AuthConfigBuilder } = await import(/* webpackChunkName: "TopAuth" */ '@top/auth-clientless-web-lite');

    return new AuthConfigBuilder()
      .withPlatform(deviceName)
      .withMVPDConfigURL(configUrl)
      .withServiceAppId(config.video.app_id)
      .withSoftwareStatement(config.video.auth.client_config)
      .withBrand(config.video.auth.brand)
      .withPollingInterval(15)
      .withEnvironment(config.video.auth.env as EnvironmentName)
      .withRegistrationURL(config.web_endpoints.tve_secondscreen_auth.href)
      .build();
  }, [config, initialQueriesComplete, minTimeSponsorShown]);

  return authConfig;
};

export type MMLChannel = 'TNT' | 'TBS' | 'truTV' | 'CBS';
