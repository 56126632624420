import { AnalyticsWorker, IAnalyticsWorker } from './analytics.worker';
import { IdentifyParams, PageParams, TrackParams } from '@segment/analytics-node';
import { logger } from 'logger';
import { IAppWorker } from './IAppWorker';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = logger.getLogger('AppCompatWorkerSingleton');

export class AppCompatWorkerSingleton implements IAppWorker {
  private static _instance: AppCompatWorkerSingleton;
  protected _analyticsWorker?: IAnalyticsWorker;

  public static get Instance() {
    return this._instance ?? (this._instance = new this());
  }

  protected get Analytics() {
    this._analyticsWorker = AnalyticsWorker;
    return this._analyticsWorker;
  }

  analyticsLoad(writeKey?: string) {
    return this.Analytics.load(writeKey);
  }

  analyticsTrack(trackParams: TrackParams) {
    return this.Analytics.track(trackParams);
  }

  analyticsPage(pageParams: PageParams) {
    return this.Analytics.page(pageParams);
  }

  analyticsIdentify(identifyParams: IdentifyParams) {
    return this.Analytics.identify(identifyParams);
  }
}

export const AppCompatWorker = AppCompatWorkerSingleton.Instance;
