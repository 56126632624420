// code from https://github.com/apollographql/apollo-cache-persist/issues/361#issuecomment-912545495
import { makeVar, ReactiveVar } from '@apollo/client';
import { logger } from '../logger';

function convertToString(value: unknown) {
  return typeof value === 'string' ? value : JSON.stringify(value);
}

export function makeVarPersisted<T>(initialValue: T, storageName: string): ReactiveVar<T> {
  let value = initialValue;
  const previousValue = localStorage.getItem(storageName);
  if (previousValue !== null) {
    try {
      value = JSON.parse(previousValue);
    } catch {
      // It wasn't JSON, assume a valid value
      value = previousValue as unknown as T;
    }
  }

  const rv = makeVar<T>(value);
  const onNextChangeListener = (newValue: T | undefined) => {
    try {
      if (newValue === undefined) {
        localStorage.removeItem(storageName);
      } else {
        localStorage.setItem(storageName, convertToString(newValue));
      }
    } catch (error) {
      logger.error(error);
    }
    rv.onNextChange(onNextChangeListener);
  };

  rv.onNextChange(onNextChangeListener);

  return rv;
}
