import { useEffect, useRef } from 'react';

// If we ever disable polling, we'll want to be able to force a re-fetch when we can.  This flag/functionality only
// exists to help webOS 4 video performance by disabling polling during video playback and forcing a re-fetch
// when we think it won't impact the user's video experience.
export function useForcedRefetch(refetch: () => void, currentValue: number) {
  const lastKnownValue = useRef<number>(currentValue);
  useEffect(() => {
    if (lastKnownValue.current !== currentValue) {
      lastKnownValue.current = currentValue;
      refetch();
    }
  }, [refetch, currentValue]);
}
